import React, { useRef, useState, cloneElement } from "react";
import stl from "./ZoomableImage.module.css";

const ZoomableImage = ({ className, children }) => {
  const imageElement = useRef();

  let imageElementScale = 1;
  let start = {};
  // const [finalScale, setFinalScale] = useState(0.5);
  let scale;
  let finalScale = 1;

  const distance = (event) => {
    return Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY
    );
  };

  const handleTouchStart = (e) => {
    {
      // console.log('touchstart', event);
      if (e.touches.length === 2) {
        e.preventDefault(); // Prevent page scroll

        // Calculate where the fingers have started on the X and Y axis
        start.x = (e.touches[0].pageX + e.touches[1].pageX) / 2;
        start.y = (e.touches[0].pageY + e.touches[1].pageY) / 2;
        start.distance = distance(e);
      }
    }
  };

  const handleTouchMove = (e) => {
    if (e.touches.length === 2) {
      e.preventDefault(); // Prevent page scroll
  
      if (e.scale) {
        scale = e.scale;
      } else {
        const deltaDistance = distance(e);
        scale = deltaDistance / start.distance;
      }
      imageElementScale = Math.min(Math.max(1, scale), 6);
  
      const deltaX =
        ((e.touches[0].pageX + e.touches[1].pageX) / 2 - start.x) * 2; // x2 for accelarated movement
      const deltaY =
        ((e.touches[0].pageY + e.touches[1].pageY) / 2 - start.y) * 2; // x2 for accelarated movement
  
      // Get viewport and image dimensions
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const imageWidth = imageElement.current.offsetWidth * imageElementScale;
      const imageHeight = imageElement.current.offsetHeight * imageElementScale;
  
      // Calculate maximum allowed translation
      const maxDeltaX = Math.max(0, (imageWidth - viewportWidth) / 2);
      const maxDeltaY = Math.max(0, (imageHeight - viewportHeight) / 2);
  
      // Clamp deltas to maximums
      const clampedDeltaX = Math.min(Math.max(deltaX, -maxDeltaX), maxDeltaX);
      const clampedDeltaY = Math.min(Math.max(deltaY, -maxDeltaY), maxDeltaY);
  
      const transform = `translate3d(${clampedDeltaX}px, ${clampedDeltaY}px, 0) scale(${imageElementScale})`;
      imageElement.current.style.transform = transform;
      imageElement.current.style.WebkitTransform = transform;
      imageElement.current.style.zIndex = "0";
    }
  };

  const handleTouchEnd = () => {
    {
      // console.log('touchend', event);
      // Reset image to it's original format

      // finalScale = scale;
      // if (finalScale <= 1) {
      imageElement.current.style.transform = "";
      imageElement.current.style.WebkitTransform = "";
      imageElement.current.style.zIndex = "";
      // }
    }
  };

  return cloneElement(children, {
    ref: imageElement,
    className: [stl.imgContainer, "hasEvents"].join(" "),
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd,
  });
};

export default ZoomableImage;
