import { useEffect, useState } from "react";
import { useTranslation } from "@i18n";
import classnames from "classnames";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import Button from "../../components/UiKit/Buttons/SubmitButton/SubmitButton";
import stl from "./Otp.module.css";
import SubmitButton from "../../components/UiKit/Buttons/SubmitButton/SubmitButton";


const VerifyOtp = ({
  phoneNumber,
  value,
  error,
  loading,
  subClassName,
  className,
  title,
  label,
  btnText,
  otpBtn,
  length,
  onEdit,
  onChange,
  onResend,
  isOtpSent,
  setIsOtpSent,
  editNumberClass,onValidate,
}) => {
  const { t } = useTranslation();

  const [showResend, setShowResend] = useState(true);
  const [showCountDown, setShowCountDown] = useState(false);
  const [showOtpSentMessage, setShowOtpSentMessage] = useState(false);
  const [isAutoTriggerd, setIsAutoTriggerd] = useState(false);

  useEffect(() => {
    if (!showOtpSentMessage) return;

    setTimeout(() => {
      setShowOtpSentMessage(false);
      setShowCountDown(true);
    }, 1000);
  }, [showOtpSentMessage]);


  useEffect(() => {

    isAutoTriggerd && setIsAutoTriggerd(false)

  }, [isAutoTriggerd]);

  useEffect(() => {
    if (!isOtpSent) return;

    setShowOtpSentMessage(true);
    setShowResend(false);
  }, [isOtpSent]);


  return (
    <div
      className={`${stl.wrapper} ${error ? stl.hasError : ""} ${className ? className : ""
        }`}
    >

      <i className={stl.otpIcon}></i>
      {title && (
        <strong className={subClassName ? subClassName : ""}>
          {t(title, { ns: "web_common" })}
        </strong>
      )}


      <label>{label}</label>
      <strong
        className={[stl.number, subClassName ? subClassName : ""].join(" ")}
      >
        {phoneNumber}
      </strong>

      <Button
        type="button"
        className={`${stl.edit} ${editNumberClass ? editNumberClass : ""}`}
        onClick={onEdit}
      >
        {t("تعديل الرقم", { ns: "web_common" })}
      </Button>

<div className={stl.input_Err_Container}>
      <OtpInput
        inputStyle={stl.optInput}
        inputType="number"
        containerStyle={stl.OtpWrapper}
        hasErrored={length === value.length && error}
        numInputs={length ? length : 4}
        onChange={onChange}
        renderSeparator={<></>}
        value={value}
        placeholder={new Array(length ? length : 4).fill("0").join("")}
        renderInput={(props) => <input {...props} type="tel" className={
          classnames({
            [stl.optInput]: true,
            [stl.otpError]: length === value.length ? error : undefined,
          })
        } />}
        shouldAutoFocus
      />

      {length === value.length && error && <span className={stl.error}>{error}</span>}
</div>
      {showResend && (
        <p>
          {t("لم يصلني الرمز", { ns: "web_common" })}.
          <Button type="button" onClick={onResend}>
            {t("إعادة إرسال", { ns: "web_common" })}
          </Button>
        </p>
      )}
      {showOtpSentMessage && (
        <p className={stl.green}>
          {t("تم إعادة إرسال رمز التحقق.", { ns: "web_common" })}
        </p>
      )}

      {showCountDown && (
        <p >
          {t("يمكنك إعادة ارسال الرمز بعد", { ns: "web_common" })}
          <Countdown
            date={Date.now() + 59 * 1000}
            renderer={({ seconds }) => <strong>{seconds}</strong>}
            onComplete={() => {
              setShowCountDown(false);
              setShowResend(true);
              setIsOtpSent(false);
            }}
          />
          {t("ثانية.", { ns: "web_common" })}
        </p>
      )}
      <SubmitButton
          onClick={onValidate}
          type="button"
          disabled={loading}
          loading={loading}
          className={[ otpBtn ? otpBtn : "", length !== value.length ? stl.notActive : undefined].join(" ")}
      >
        {btnText}
      </SubmitButton>

    </div>
  );
};

export default VerifyOtp;
