import fetcher from "../../common/axiosInstance";
import { AddRemoveFromFavorites } from "@/utils/auth";
import { LOGIN_RESOURCE } from "@/utils/constants";
import {
  UPDATE_USER_DATA,
  GET_USER_FAVORITE,
} from "../types";
import { authLoading } from "./authActions";
import { footerLoading, loading } from "./commonActions";
import FetchSyarahv2 from "@/common/fetchSyarahv2";

///////////////////////////////// old

export const setUserLoginResource = (source, postId) => async (dispatch) => {
  dispatch({
    type: LOGIN_RESOURCE,
    payload: { source: source, postId: postId },
  });
};

///////////////////////////////// new
//user (customer) actions

export const customerAuth = (number, cb, fcb) => async (dispatch) => {
  dispatch(footerLoading(true));
  const data = new FormData();
  data.append("phone_number", number);

  const res = await FetchSyarahv2().post("/auth/login-or-signup", data);

  if (res?.data?.code === 200)
    cb({ hasAccount: res.data.data?.is_verified === false ? false : true });
  if (res?.data?.code === 230 || res?.data?.code === 422) fcb(res.data.errors);

  dispatch(footerLoading(false));
};

export const updateUserCityProfile =
  (city, successCallBack) => async (dispatch) => {
    dispatch(loading(true));
    const formData = new FormData();
    formData.append("city_id", city);
    const res = await fetcher().post(`customer/update-profile`, formData);
    if (res.data.success) {
      dispatch({
        type: UPDATE_USER_DATA,
        payload: res.data.data,
      });
      successCallBack(res.data.data);
    }
    dispatch(loading(false));
  };

export const updateUserNameProfile =
  (name, successCallBack, failedCallBack = null) =>
  async (dispatch) => {
    dispatch(footerLoading(true));
    const formData = new FormData();
    formData.append("full_name", name);
    const res = await fetcher().post(`customer/update-profile`, formData);
    if (res.data.success) {
      dispatch({
        type: UPDATE_USER_DATA,
        payload: res.data.data,
      });
      successCallBack(res.data.data);
    } else {
        failedCallBack && failedCallBack(res?.data?.errors?.full_name);
    }
    dispatch(footerLoading(false));
  };

// todo REDUX removal
export const updateUserMobileProfile =
  (mobile, successCallBack, failedCallBack) => async (dispatch) => {
    dispatch(footerLoading(true));
    const formData = new FormData();
    formData.append("phone_number", mobile);
    formData.append("is_phone_number", true);
    const res = await fetcher().post(`customer/update-profile`, formData);
    if (res.data.success) {
      dispatch({
        type: UPDATE_USER_DATA,
        payload: res.data.data,
      });
      successCallBack();
    } else {
      failedCallBack(res.data.errors);
    }
    dispatch(footerLoading(false));
  };

///////////////////////////////// new



export const togglePostToFavorite =
  (postId, isOnline, page, cb=undefined) =>
  async (dispatch) => {
    dispatch(authLoading(true));
    const data = new FormData();
    data.append("post_id", postId);
    data.append("is_online", isOnline);
    if (page === "favorite") data.append("with_list", 1);


    const res = await fetcher().post("/customer/add-delete-favourite", data);
    if (res?.data?.code === 200) {
      //window.sessionStorage.setItem('favorite_post_id', postId);
      cb && cb(res.data.data.is_favorite);
      AddRemoveFromFavorites(+postId, res.data.data.is_favorite);
      if (page === "favorite")
        dispatch({
          type: GET_USER_FAVORITE,
          payload: res.data.data,
        });
    }
    dispatch(authLoading(false));

  };
