import React from "react";
var __jsx = React.createElement;
import "../../_FIX_ME_STYLES/home.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Layout from "@/_LayoutsRelated/layout/Layout";
import HomePage from "@/pages/home/homePageComponents/HomePage";
import stl from "./index.module.css";
import { useFullPageData } from "@/common/Contexts/FullPageContext";
import { PageNamesEnum } from "@/routes/routes";
function Home() {
  var fullPageData = useFullPageData(PageNamesEnum.PAGE_NAME__Home);
  if (!fullPageData) return null;
  var state = useSelector(function (state) {
    return state;
  });
  var location = useLocation();
  var isNew = location.pathname.includes("/home/new-cars");
  return __jsx(Layout, {
    hasNavBar: true,
    pageName: "home",
    hasMobFooter: true,
    homeSearchHeader: true,
    loading: state.common.loading,
    hideAdvSearchMobile: true,
    disableLoader: true,
    hasContactUs: true,
    loggedin: state.auth.loggedin,
    notification: state.auth.notification,
    authenticationMsg: true,
    mainClass: [state.common.loading ? stl.flashLoading : undefined, stl.trans].join(" "),
    revampedHEading: true
  }, __jsx(HomePage, {
    data: fullPageData.homeDataNew,
    isNew: isNew,
    isUsed: false,
    flashLoading: state.common.loading
  }));
}
export default Home;