import { HEADLESS_CMS_IMAGE_URL, SELECTED_ENV } from "@globalConfig";
import { ucWords } from "@/utils/stringHelpers";
import { CTAType } from "../../../../types/models/cms";
export var financeImageHelper = function financeImageHelper(url) {
  return (HEADLESS_CMS_IMAGE_URL + url).replace('{env}', SELECTED_ENV === 'local' ? 'dev' : SELECTED_ENV);
};
export var financeButtonColorHelper = function financeButtonColorHelper(type) {
  return type === CTAType.PRIMARY ? '#154F9C' : '#ff571e';
};
export var financeTranslateText = function financeTranslateText(string, make, model, lang) {
  if (!string) return '';
  var nameField = lang === 'en' ? 'name_en' : 'name';
  var makeName = make ? make[nameField] || make.name : '';
  var modelName = model ? model[nameField] || model.name : '';
  return string.replaceAll('{make}', ucWords(makeName)).replaceAll('{model}', ucWords(modelName));
};
export var financeUrlHelper = function financeUrlHelper(url, lang) {
  var _make$name_en, _model$name_en;
  var make = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var model = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  if (!url) return '';
  var langUrl = lang === 'en' ? '/en' : '';
  var makeSlug = (make === null || make === void 0 ? void 0 : (_make$name_en = make.name_en) === null || _make$name_en === void 0 ? void 0 : _make$name_en.slugify()) || "";
  var modelSlug = (model === null || model === void 0 ? void 0 : (_model$name_en = model.name_en) === null || _model$name_en === void 0 ? void 0 : _model$name_en.slugify()) || "";
  return langUrl + url.replaceAll('{make}', makeSlug).replaceAll('{model}', modelSlug);
};