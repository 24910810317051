import {useTranslation} from "@i18n";
import stl from "./GrantedPopup.module.css";
import {CDN_LINK} from "@globalConfig";

const GrantedPopup = () => {
  const { t } = useTranslation();
  return (
    <div >
      <div className={stl.headSection}>
        <img
          src={CDN_LINK + "/assets/images/inspected-guaranteed-ar.svg"}
          alt=""
          className="m-hide"
        />
        <img
          src={CDN_LINK + "/assets/images/garnteed-program.svg"}
          alt=""
          className="m-hide"
        />
        <img
          src={CDN_LINK + "/assets/images/garntee-head-group.svg"}
          alt=""
          className="m-show"
        />
      </div>

      <div className={stl.point}>
        <img
          src={CDN_LINK + "/assets/images/guarantee-10.svg"}
          alt=""
        />
        <div>
          <strong>
            {t("ضمان استرداد خلال 10 أيام", { ns: "web_search" })}
          </strong>
          <p>
            {t("تقدر لأي سبب خلال 10 أيام ترجع السيارة و تسترد كامل فلوسك.", {
              ns: "web_search",
            })}
          </p>
        </div>
      </div>

      <div className={stl.point}>
        <img
          src={CDN_LINK + "/assets/images/shield.svg"}
          alt=""
        />
        <div>
          <strong>
            {t("ضمان سنة او 20 الف كم", {
              ns: "web_search",
            })}
          </strong>
          <p>
            {t(
              "نوفر لك ضمان مجاني على قطع وأجزاء السيارة بالأضافة الى خدمة المساعدة على الطريق.",
              {
                ns: "web_search",
              }
            )}
          </p>
        </div>
      </div>
      <div className={stl.point}>
        <img
          src={CDN_LINK + "/assets/images/garnteee-check.svg"}
          alt=""
        />
        <div>
          <strong>
            {t("فحص أكثر من 200 نقطة", {
              ns: "web_search",
            })}
          </strong>
          <p>
            {t(
              "نفحص سياراتنا المستعملة في أكثر من 200 نقطة والتأكد من نظافتها وأدائها.",
              {
                ns: "web_search",
              }
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
export default GrantedPopup;
