import { useLocation, useSearchParams } from "react-router-dom";
import CardBody from "./CardBody/CardBody";
import CardFooter from "./CardFooter/CardFooter";
import CardHeader from "./CardHeader/CardHeader";
import stl from "./UnbxdCard.module.css";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import { serverUrl } from "@globalConfig";
import useHelpers from "@hooks/useHelpers";

const UnbxdCard = ({
  post,
  showGrantedPopup,
  metaData,
  isEager,
  isCustomDesign,
  hideFooterDeskMob,
  className,
  hideFavIcon,
  rank,
  page,
  isRecommendations,
  hideGrantedLabel,
  hideMarkitingLabel,
  hideDiscountLabel,
  TheImage = null,
  CustomTag = "h2",
  is_rec = false,
  HasImageSlider = false,
  slideOnlyOnArr=false,
  isAnchor=false,
  onClick = () => {}
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {createUrl} = useHelpers();
  const isBrowseSearch = location.pathname.includes("/autos");

  const listing_type = searchParams.get("text")
    ? "search"
    : isBrowseSearch
    ? "browse"
    : "filter";

  let modifiedLink = createUrl(post?.product_url);


  return (
    <div
      state={{ screen_view_referral: "referralLink" }}
      className={`${stl.wrapper} ${className ? className : ""} `}
      onClick={() => {
        const params = {
          action: "click",
          query: searchParams.get("text") || "",
          pid: post.uniqueId,
          url: serverUrl + location.pathname + location.search,
          page: metaData?.category_path || "",
          page_type: metaData?.category_path ? "BOOLEAN" : "",
        };

        if (!isBrowseSearch || !metaData?.category_path) {
          // delete params.query;
          delete params.page;
          delete params.page_type;
        }

        if (isBrowseSearch && metaData?.category_path) {
          delete params.query;
        }
        unbxdAnalytics(params);
        onClick && onClick()
      }}
    >
      <CardHeader
          rank={rank}
        to_link={`${modifiedLink}${isRecommendations ? `&rank=${rank}` : ""}${
          page && rank && listing_type
            ? `#page_number=${page}&product_position=${rank}&listing_type=${listing_type}`
            : ""
        }${is_rec ? `#is_rec=1&product_position=${rank}` : ""}`}
        post={post}
        isEager={isEager}
        isCustomDesign={isCustomDesign}
        hideFavIcon={hideFavIcon}
        hideMarkitingLabel={hideMarkitingLabel}
        hideDiscountLabel={hideDiscountLabel}
        TheImage={TheImage}
        HasImageSlider={HasImageSlider}
        slideOnlyOnArr={slideOnlyOnArr}
        isAnchor={isAnchor}
      />
      <CardBody
        to_link={`${modifiedLink}${isRecommendations ? `&rank=${rank}` : ""}${
          page && rank && listing_type
            ? `#page_number=${page}&product_position=${rank}&listing_type=${listing_type}`
            : ""
        }${is_rec ? `#is_rec=1&product_position=${rank}` : ""}`}
        post={post}
        showGrantedPopup={showGrantedPopup}
        isCustomDesign={isCustomDesign}
        hideGrantedLabel={hideGrantedLabel}
        CustomTag={CustomTag}
        isAnchor={isAnchor}
      />
      {!hideFooterDeskMob && (
        <CardFooter
          to_link={`${modifiedLink}${isRecommendations ? `&rank=${rank}` : ""}${
            page && rank && listing_type
              ? `#page_number=${page}&product_position=${rank}&listing_type=${listing_type}`
              : ""
          }${is_rec ? `#is_rec=1&product_position=${rank}` : ""}`}
          tags={post.tags}
          isCustomDesign={isCustomDesign}
          isAnchor={isAnchor}
        />
      )}
    </div>
  );
};

export default UnbxdCard;
