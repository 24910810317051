import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "@i18n";
import Layout from "@/_LayoutsRelated/layout/Layout";
import HeroArea from "@componentsShared/HeroArea/HeroArea";
import LatestPosts from "@/pages/CarShowRooms/CarShowRoomsComponents/LatestPosts/LatestPosts";
import BreadCrumb from "@componentsShared/BreadCrumb";
import stl from "./CarShowRooms.module.css";
import VisitUsComponent from "@/pages/CarShowRooms/CarShowRoomsComponents/VisitUsComponent/VisitUsComponent";
import { getUSPData } from "./utils";
import { USP } from "@/pages/CarShowRooms/USP/USP";
import { CDN_LINK } from "@globalConfig";
import { useFullPageData } from "@/common/Contexts/FullPageContext";
import { PageNamesEnum } from "@/routes/routes";
var CarShowRooms = function CarShowRooms() {
  var _USPData$images;
  var _useTranslation = useTranslation(["web_car_show_rooms", "web_common"]),
    t = _useTranslation.t;
  var USPData = getUSPData(t);
  var posts = useFullPageData(PageNamesEnum.PAGE_NAME__CarShowRooms);
  if (!posts) return null;
  return __jsx(Layout, {
    headHideLinks: true,
    hasNavBar: true,
    authenticationMsg: true,
    mainClass: "",
    linksForSearch: true,
    hideOrangeHeadBtn: true,
    disableLoader: true,
    hideAppBanner: true,
    hasMobFooter: true,
    loading: true
  }, __jsx("div", {
    className: stl.C_container
  }, __jsx(HeroArea, {
    title1: t("سيارة أكبر معرض سيارات أونلاين في السعودية", {
      ns: "web_car_show_rooms"
    }),
    subTitle: t("نوفر لك أوسع تشكيلة سيارات جديدة وكالة ومستعملة مضمونة", {
      ns: "web_car_show_rooms"
    }),
    image: CDN_LINK + "/assets/images/carShowRooms/car-show-rooms.png",
    backGroundColor: "#266FD1"
  }), __jsx("div", {
    className: stl.C_content
  }, __jsx(BreadCrumb, {
    className: stl.brdCrmb,
    links: [{
      title: t("معرض سيارة", {
        ns: "web_common"
      })
    }]
  }), posts ? __jsx(LatestPosts, {
    posts: posts,
    title: t("أحدث السيارات في معرض سيارة", {
      ns: "web_common"
    }),
    buttonUrl: "/autos",
    buttonText: t("تصفح جميع السيارات", {
      ns: "web_common"
    }),
    buttonColor: stl.buttonRed,
    eagerImgNum: 4,
    CustomTag: "h3"
  }) : null, __jsx("div", {
    className: stl.uspsContainer
  }, __jsx("h2", null, t("ليش سيارة أكبر معرض سيارات أونلاين؟", {
    ns: "web_car_show_rooms"
  })), __jsx("div", {
    className: stl.flxCards
  }, USPData === null || USPData === void 0 ? void 0 : (_USPData$images = USPData.images) === null || _USPData$images === void 0 ? void 0 : _USPData$images.map(function (usp, idx) {
    return __jsx(USP, {
      key: idx,
      usp: usp
    });
  })))), __jsx(VisitUsComponent, {
    title: t("قم بزيارة معرض سيارة", {
      ns: "web_common"
    })
  })));
};
export default CarShowRooms;