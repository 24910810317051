var __jsx = React.createElement;
import React from "react";
import stl from "./BenefitsList.module.css";
var BenefitsList = function BenefitsList(_ref) {
  var title = _ref.title,
    benefits = _ref.benefits,
    image = _ref.image,
    reversed = _ref.reversed,
    _ref$loading = _ref.loading,
    loading = _ref$loading === void 0 ? "lazy" : _ref$loading;
  return __jsx("div", {
    className: ["container", stl.singleBenefitContainer].join(" ")
  }, __jsx("div", {
    className: stl.BenefitsContainer,
    style: {
      flexDirection: reversed ? "row-reverse" : "row"
    }
  }, __jsx("div", {
    className: stl.imageCont
  }, __jsx("img", {
    loading: loading,
    src: image,
    alt: "title",
    width: "50px",
    height: "50px"
  })), __jsx("div", {
    className: stl.textCont
  }, __jsx("h2", null, title), __jsx("div", {
    className: stl.pointsCont
  }, benefits.map(function (benefit, index) {
    return __jsx("p", {
      key: index
    }, __jsx("span", null, __jsx("img", {
      src: "/assets/images/checked.svg",
      alt: "checked",
      width: "24px",
      height: "24px"
    })), benefit);
  })))));
};
export default BenefitsList;