import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import React from "react";
var __jsx = React.createElement;
import stl from "./BreadCrumb.module.css";
import { serverUrl } from "@globalConfig";
import { useTranslation } from "@i18n";
import useWebView from "@hooks/useWebview";
import useIsWebView from "@hooks/useIsWebview";
import useHelpers from "@hooks/useHelpers";
var linkWithAbsoluteUrl = function linkWithAbsoluteUrl(link) {
  if (link.includes("http")) {
    return link;
  }
  return serverUrl + link;
};
var BreadCrumb = function BreadCrumb(_ref) {
  var links = _ref.links,
    hasSchemaOrg = _ref.hasSchemaOrg,
    className = _ref.className;
  var _useTranslation = useTranslation(["web_common"]),
    t = _useTranslation.t;
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var homeUrl = createUrl("/");
  var webview = useWebView();
  var isWebView = useIsWebView();
  // check home link is exist or not, and add it if not exist
  // FIXME, this is a temporary solution, until full migration of all breadcrums
  var needAddingHome = links.length > 0 && links[0].link !== homeUrl;
  if (needAddingHome) {
    links.unshift({
      title: t("الرئيسية", {
        ns: "web_common"
      }),
      link: homeUrl
    });
  }
  var schema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: links.map(function (breadcrumbItem, i) {
      return _objectSpread({
        "@type": "ListItem",
        position: i + 1,
        name: breadcrumbItem.title
      }, breadcrumbItem.link ? {
        item: linkWithAbsoluteUrl(breadcrumbItem.link)
      } : {});
    })
  };
  return __jsx(React.Fragment, null, hasSchemaOrg && __jsx("script", {
    type: "application/ld+json",
    dangerouslySetInnerHTML: {
      __html: JSON.stringify(schema)
    }
  }), __jsx("ul", {
    className: [stl.breadCrumb2, "container", className].join(" ")
  }, links && links.map(function (breadcrumbItem, i) {
    if (!(breadcrumbItem !== null && breadcrumbItem !== void 0 && breadcrumbItem.title)) {
      return null;
    }
    var onclick = function onclick(e) {
      webview.closeScreen()["catch"](function () {
        if (isWebView) {
          e.preventDefault();
          return document.location.href = "app://close";
        }
        if (breadcrumbItem.event) {
          breadcrumbItem.event();
        }
      });
    };
    return breadcrumbItem.title.trim() !== "" && __jsx("li", {
      key: breadcrumbItem.title + i
    }, breadcrumbItem.link ? __jsx("a", {
      href: linkWithAbsoluteUrl(breadcrumbItem.link),
      onClick: onclick
    }, breadcrumbItem.title) : breadcrumbItem.title);
  })));
};
export default BreadCrumb;