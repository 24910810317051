import _toConsumableArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import React from "react";
var __jsx = React.createElement;
import stl from './RecommendationSlider.module.css';
import { useTranslation } from "@i18n";
import { useEffect, useState } from "react";
import UnbxdCard from "@/components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import RecommendationSliderSkeleton from "@/pages/home/homePageComponents/components/recommendation/RecommendationSliderSkeleton";
import { api__getRecommendedPosts } from "@/apis/posts/recommendation";
import cookieClient from "react-cookies/build/cookie";
import { Link } from "react-router-dom";
import { trackEvents } from "@/common/events/events";
import { getUserFromLocalStorage } from "@/utils/auth";
import useHelpers from "@hooks/useHelpers";
import { CDN_LINK } from "@globalConfig";
import { checkFeatureFlag } from "@/common/config/websiteConfig";
/** ---------------------------------------- **/
var RecommendationSlider = function RecommendationSlider() {
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  var _useState = useState([]),
    similar = _useState[0],
    setSimilar = _useState[1];
  var _useState2 = useState(true),
    loading = _useState2[0],
    setLoading = _useState2[1];
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  useEffect(function () {
    if (!checkFeatureFlag("FF_RECOMMENDATION_ENABLED")) {
      return;
    }
    var userId = cookieClient.load("UUID") || null;
    api__getRecommendedPosts(userId, 4).then(function (data) {
      if (data) {
        setSimilar(_toConsumableArray(data));
        setLoading(false);
      }
    });
    return function () {
      return setSimilar([]);
    };
  }, []);
  useEffect(function () {
    if (similar !== null && similar !== void 0 && similar.length) {
      var _userData$phone_numbe;
      var user_uuid = cookieClient.load("UUID");
      var userData = getUserFromLocalStorage();
      trackEvents("recommendation_section_appeared", {
        uuid: user_uuid || null,
        user_id: userData !== null && userData !== void 0 && userData.phone_number ? "966".concat((_userData$phone_numbe = userData.phone_number) === null || _userData$phone_numbe === void 0 ? void 0 : _userData$phone_numbe.slice(1)) : "null",
        referral_location: "home",
        results_count: similar.length
      });
    }
  }, [similar]);
  return __jsx(React.Fragment, null, __jsx("section", {
    className: stl.container
  }, __jsx("div", {
    className: stl.big
  }, __jsx("h2", null, t("سيارات خصيصاً لك", {
    ns: "web_common"
  }))), loading ? __jsx("ul", {
    className: stl.cardsWrapper
  }, Array.from({
    length: 4
  }).map(function (_, idx) {
    return __jsx(RecommendationSliderSkeleton, {
      key: "un-skeleton-".concat(idx)
    });
  })) : __jsx(React.Fragment, null, __jsx("ul", {
    className: [stl.cardsWrapper, (similar === null || similar === void 0 ? void 0 : similar.length) < 3 ? stl.cardsWrapperMin : ''].join(' ')
  }, similar.map(function (post, idx) {
    return __jsx("li", {
      className: stl.card
    }, __jsx(UnbxdCard, {
      key: post.id,
      post: post,
      rank: idx + 1,
      onClick: function onClick() {
        var _userData$phone_numbe2;
        var user_uuid = cookieClient.load("UUID") || null;
        var userData = getUserFromLocalStorage();
        trackEvents("recommendation_car_clicked", {
          uuid: user_uuid || null,
          user_id: userData !== null && userData !== void 0 && userData.phone_number ? "966".concat((_userData$phone_numbe2 = userData.phone_number) === null || _userData$phone_numbe2 === void 0 ? void 0 : _userData$phone_numbe2.slice(1)) : "null",
          post_id: post.id,
          referral_location: "home"
        });
      }
    }));
  }), similar.length > 3 && __jsx(React.Fragment, null, __jsx("li", {
    className: stl.showAllRecommendedCars
  }, __jsx(Link, {
    to: createUrl("/site/recommendation-list"),
    className: stl.showAllLinkMobile,
    onClick: function onClick() {
      var _userData$phone_numbe3;
      var user_uuid = cookieClient.load("UUID") || null;
      var userData = getUserFromLocalStorage();
      trackEvents("recommendation_listing_page_viewed", {
        uuid: user_uuid || null,
        user_id: userData !== null && userData !== void 0 && userData.phone_number ? "966".concat((_userData$phone_numbe3 = userData.phone_number) === null || _userData$phone_numbe3 === void 0 ? void 0 : _userData$phone_numbe3.slice(1)) : "null",
        referral_location: "home"
      });
    }
  }, __jsx("span", {
    className: stl.showAllText
  }, t("إعرض المزيد من السيارات", {
    ns: "web_common"
  })), __jsx("span", null, __jsx("img", {
    className: stl.arrowLeft,
    src: CDN_LINK + "/assets/images/arrow-left-white.svg",
    alt: ""
  })))))), similar.length > 3 && __jsx(React.Fragment, null, __jsx("div", {
    className: stl.showRecommendedCarsSection
  }, __jsx(Link, {
    to: createUrl("/site/recommendation-list"),
    onClick: function onClick() {
      var _userData$phone_numbe4;
      var user_uuid = cookieClient.load("UUID") || null;
      var userData = getUserFromLocalStorage();
      trackEvents("recommendation_listing_page_viewed", {
        uuid: user_uuid || null,
        user_id: userData !== null && userData !== void 0 && userData.phone_number ? "966".concat((_userData$phone_numbe4 = userData.phone_number) === null || _userData$phone_numbe4 === void 0 ? void 0 : _userData$phone_numbe4.slice(1)) : "null",
        referral_location: "home"
      });
    },
    className: stl.showAllRecommendedCarsButton
  }, t("شاهد جميع السيارات المقترحة", {
    ns: "web_common"
  })))))));
};
export default RecommendationSlider;