import { useNavigate, useLocation, useParams } from "react-router-dom";
function WithRouter(Page) {
  return (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    return (
      <Page
        navigate={navigate}
        location={location}
        {...props}
        params={params}
      />
    );
  };
}

export default WithRouter;
