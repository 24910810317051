import React from "react";
var __jsx = React.createElement;
import stl from "./USP.module.css";
export var USP = function USP(_ref) {
  var usp = _ref.usp;
  return __jsx("div", {
    className: stl.uspContainer
  }, __jsx("img", {
    src: usp.image,
    alt: usp.title,
    width: "150px",
    height: "200px",
    loading: "lazy"
  }), __jsx("h3", null, usp.title), __jsx("p", null, usp.desc));
};