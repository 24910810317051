import React from "react";
var __jsx = React.createElement;
import { ComponentType } from "../../../../types/models/financing";
import { Fragment } from "react";
import { renderHeadlessComponent } from "@/pages/financing/FinancingPageRoot";
import stlBanks from "@/pages/financing/financingBanks/FinanceBanks.module.css";
import stl from "@/pages/financing/financingBanks/FinanceBanks.module.css";
import { financeImageHelper } from "../helpers/financingHelpers";
var validComponents = [ComponentType.HEADER_PANEL, ComponentType.BREADCRUMB, ComponentType.FULL_BANKS_LIST, ComponentType.BUTTON_LINK];
export var FullBanksList = function FullBanksList(_ref) {
  var banks = _ref.banks;
  return __jsx("div", {
    className: stlBanks.banksContainer
  }, banks.map(function (bank, idx) {
    return __jsx("div", {
      className: stl.singleBankHolder
    }, __jsx("div", null, __jsx("img", {
      loading: idx < 12 ? "eager" : "lazy",
      src: financeImageHelper(bank.logo.url),
      width: "126px",
      height: "120px",
      alt: bank.logo.caption || bank.name
    })), __jsx("h2", null, bank.name));
  }));
};
var FinanceBanks = function FinanceBanks(financingPageResponse) {
  return __jsx(React.Fragment, null, financingPageResponse.financePage.contentSections.filter(function (section) {
    return validComponents.includes(section.__component);
  }).map(function (section, index) {
    return __jsx(Fragment, {
      key: index
    }, renderHeadlessComponent(financingPageResponse, section));
  }));
};
export default FinanceBanks;