var __jsx = React.createElement;
import React from "react";
import stl from "./Button.module.css";
var ButtonLink = function ButtonLink(_ref) {
  var children = _ref.children,
    link = _ref.link,
    newTab = _ref.newTab,
    className = _ref.className,
    style = _ref.style;
  return __jsx("a", {
    target: newTab ? "_blank" : "_self",
    style: style || {},
    href: link,
    className: "".concat(stl.btn, " ").concat(className ? className : "")
  }, children);
};
export default ButtonLink;