import _defineProperty from "/usr/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import React from "react";
var __jsx = React.createElement;
import stl from "./CarCardsGroup.module.css";
import UnbxdCard from "../../../../components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import { thumbUrl } from "@/utils/imagesHelpers";
import { useLang } from "@/common/Contexts/LanguageContext";
import { financeUrlHelper } from "@/pages/financing/helpers/financingHelpers";
import useHelpers from "@hooks/useHelpers";
var CarCardsGroup = function CarCardsGroup(props) {
  var _props$cta;
  var lang = useLang();
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var url = financeUrlHelper(props.cta.url, lang, props.make, props.model) || createUrl("/filters", _defineProperty({}, "condition_id", 0));
  return __jsx(React.Fragment, null, __jsx("div", {
    className: "SuggestionsContainerOnline"
  }, __jsx("div", {
    className: ["container", stl.revampedHome].join(" ")
  }, __jsx("h2", {
    className: ["big", stl.revampedTitle].join(" ")
  }, props.title), __jsx("div", {
    className: [stl.allCarsResult, stl.allCarsResultRevamped].join(" ")
  }, props.products.map(function (post, i) {
    return __jsx(UnbxdCard, {
      CustomTag: "h3",
      isEager: false,
      imageSize: "small",
      key: post.id,
      post: post,
      showGrantedPopup: function showGrantedPopup() {},
      isOnline: true,
      hideFavIcon: true,
      TheImage: function TheImage() {
        return __jsx("img", {
          alt: "",
          src: thumbUrl(post === null || post === void 0 ? void 0 : post.image_url, "online-v1", "0x300"),
          width: 110,
          loading: "lazy",
          height: 125
        });
      }
    });
  })), __jsx("a", {
    href: url,
    className: [["redBtn", "moreSameCar", stl.showMorwBtn].join(" ")].join(" ")
  }, props === null || props === void 0 ? void 0 : (_props$cta = props.cta) === null || _props$cta === void 0 ? void 0 : _props$cta.text))));
};
export default CarCardsGroup;