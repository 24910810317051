import React from "react";
var __jsx = React.createElement;
import { useTranslation } from "@i18n";
import stl from "./VisitUsComponent.module.css";
var VisitUsComponent = function VisitUsComponent(_ref) {
  var title = _ref.title;
  var _useTranslation = useTranslation([, "web_common"]),
    t = _useTranslation.t;
  return __jsx(React.Fragment, null, __jsx("div", {
    className: stl.visitUsCont
  }, __jsx("h2", null, title), __jsx("div", {
    className: stl.flx
  }, __jsx("div", null, __jsx("p", null, t("شركة سيارة", {
    ns: "web_common"
  })), __jsx("span", null, "|"), __jsx("p", null, t("حي النهضة، طريق خريص", {
    ns: "web_common"
  })), __jsx("span", {
    className: stl.mob_hide
  }, "|")), __jsx("div", null, __jsx("p", null, t("13222 الرياض", {
    ns: "web_common"
  })), __jsx("span", null, "|"), __jsx("p", null, t("المملكة العربية السعودية", {
    ns: "web_common"
  })))), __jsx("iframe", {
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3623.0179846607275!2d46.8348856!3d24.7605725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2fab6abe210645%3A0xb6b6a73b3bdd70b6!2sSyarah%20Ltd.!5e0!3m2!1sen!2sjo!4v1700392307735!5m2!1sen!2sjo",
    width: "100%",
    height: "400",
    loading: "lazy",
    referrerPolicy: "no-referrer-when-downgrade"
  })));
};
export default VisitUsComponent;