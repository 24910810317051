import stl from "./TextImageComponent.module.css";

const TextImageComponent = ({
  title,
  image,
  revRow,
  blueBackground = null,
  services = null,
  content,
  className = ""
}) => {
  return (
    <>
      <div className={`${className ? className : ""} ${stl.customFlexContainer} ${revRow ? stl.revRow : ""}`}>
        <div
          className={`${stl.infoPart} ${services ? stl.servicesHeader : ""}`}
        >
          {services ? <h3>{title}</h3> : <h2>{title}</h2>}
          {content?.map((item, idx) => {
            return (
              <ul key={idx}>
                <li
                  key={idx}
                  className={`${item.isBulltet ? stl.withBullet : ""}`}
                >
                  {item.text}
                </li>
              </ul>
            );
          })}
        </div>
        <div
          className={[
            stl.imgPart,
            blueBackground ? stl.trphy : "",
            services ? stl.grayBackground : "",
          ].join(" ")}
        >
          <img width={147} height={375} src={image} alt={title} loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default TextImageComponent;
