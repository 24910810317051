import React from "react";
var __jsx = React.createElement;
import stl from "./Partners.module.css";
import { financeImageHelper } from "@/pages/financing/helpers/financingHelpers";
var Partners = function Partners(props) {
  var title = props.title,
    banks = props.banks,
    buttonText = props.buttonText,
    buttonUrl = props.buttonUrl;
  return __jsx("div", {
    className: stl.partnersContainer
  }, __jsx("h2", null, title), __jsx("div", {
    className: stl.banksCont
  }, banks.map(function (bank) {
    return __jsx("div", null, __jsx("img", {
      loading: "lazy",
      src: financeImageHelper(bank.logo.url),
      alt: bank.logo.caption || bank.name,
      width: "126px",
      height: "120px"
    }), __jsx("h3", null, bank.name));
  })), __jsx("a", {
    href: buttonUrl,
    className: ["blueBtn", stl.partnersButton].join(" ")
  }, buttonText));
};
export default Partners;