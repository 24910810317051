import React from "react";
var __jsx = React.createElement;
import { useState } from "react";
import stl from "./LatestPosts.module.css";
import UnbxdCard from "../../../../components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";
import BoxLoading from "@componentsShared/placeholders/BoxLoading/BoxLoading";
import SubmitButton from "../../../../components/UiKit/Buttons/SubmitButton/SubmitButton";
import ArrowSVG from "../../../../SVGObjects/UiKit/ArrowSVG";
import BottomSheetPopup from "@componentsShared/BottomSheetPopup__OLD/BottomSheetPopup";
import GrantedPopup from "../../../../components/SearchComponent/NewComponents/GrantedPopup/GrantedPopup";
import useHelpers from "@hooks/useHelpers";
var LatestPosts = function LatestPosts(_ref) {
  var title = _ref.title,
    posts = _ref.posts,
    _ref$lazyOnly = _ref.lazyOnly,
    lazyOnly = _ref$lazyOnly === void 0 ? false : _ref$lazyOnly,
    _ref$hasArrow = _ref.hasArrow,
    hasArrow = _ref$hasArrow === void 0 ? false : _ref$hasArrow,
    buttonText = _ref.buttonText,
    buttonUrl = _ref.buttonUrl,
    buttonColor = _ref.buttonColor,
    _ref$eagerImgNum = _ref.eagerImgNum,
    eagerImgNum = _ref$eagerImgNum === void 0 ? 8 : _ref$eagerImgNum,
    _ref$CustomTag = _ref.CustomTag,
    CustomTag = _ref$CustomTag === void 0 ? "h2" : _ref$CustomTag;
  var _useHelpers = useHelpers(),
    navigateTo = _useHelpers.navigateTo;
  var _useState = useState(false),
    showGrantedPopup = _useState[0],
    setShowGrantedPopup = _useState[1];
  return __jsx(React.Fragment, null, __jsx("div", {
    className: stl.CLO_component
  }, title && __jsx("h2", null, title), __jsx("div", {
    className: stl.CLO_postsContainer
  }, posts.length ? posts === null || posts === void 0 ? void 0 : posts.map(function (post, idx) {
    return __jsx(UnbxdCard, {
      key: post.id,
      post: post,
      showGrantedPopup: function showGrantedPopup() {
        return setShowGrantedPopup(true);
      },
      isEager: lazyOnly ? false : idx < eagerImgNum,
      CustomTag: CustomTag
    });
  }) : __jsx(BoxLoading, {
    divHeight: "325px",
    numberOfLoaders: "8",
    hasCar: true
  })), __jsx(SubmitButton, {
    className: "redBtn ".concat(stl.customBtn, " ").concat(buttonColor ? buttonColor : "", " ").concat(hasArrow ? stl.customBtnFlex : ""),
    onClick: function onClick() {
      return navigateTo(buttonUrl);
    },
    loading: false
  }, buttonText, hasArrow && __jsx(ArrowSVG, {
    filledColor: "white",
    className: "m-show ".concat(stl.rotateTheSVG)
  }))), __jsx(BottomSheetPopup, {
    show: showGrantedPopup,
    onClose: function onClose() {
      return setShowGrantedPopup(false);
    }
  }, __jsx(GrantedPopup, null)));
};
export default LatestPosts;