import { CDN_LINK } from "@globalConfig";
export var getUSPData = function getUSPData(t) {
  return {
    title: t("ليش سيارة أكبر معرض سيارات أونلاين؟", {
      ns: "web_car_show_rooms"
    }),
    images: [{
      title: t("أكبر تشكيلة في المملكة", {
        ns: "web_car_show_rooms"
      }),
      desc: t("وفرنا لك أكثر من 2,000 سيارة تختار منها", {
        ns: "web_car_show_rooms"
      }),
      image: CDN_LINK + "/assets/images/USP/carShowRooms/biggest_collection.svg"
    }, {
      title: t("اشترها، جربها 10 أيام مجاناً", {
        ns: "web_car_show_rooms"
      }),
      desc: t("جرب السيارة 10 أيام وإذا ما جازتلك تقدر لأي سبب ترجعها وتسترد ثمنها", {
        ns: "web_car_show_rooms"
      }),
      image: CDN_LINK + "/assets/images/USP/carShowRooms/try_it_10.svg"
    }, {
      title: t("ضمان سنة كاملة", {
        ns: "web_car_show_rooms"
      }),
      desc: t("ضمان يشمل إصلاح وقطع غيار علينا ومساعدة على الطريق!", {
        ns: "web_car_show_rooms"
      }),
      image: CDN_LINK + "/assets/images/USP/carShowRooms/warranty.svg"
    }, {
      title: t("حنا نوصلها لين عندك", {
        ns: "web_car_show_rooms"
      }),
      desc: t("خليك بالبيت واشتري سيارتك اونلاين وحنا نوصلها لين عندك", {
        ns: "web_car_show_rooms"
      }),
      image: CDN_LINK + "/assets/images/USP/carShowRooms/delivery.svg"
    }]
  };
};