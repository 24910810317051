import _extends from "/usr/app/node_modules/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { ComponentType } from "../../../types/models/financing";
import { useFullPageData } from "@/common/Contexts/FullPageContext";
import Layout from "@/_LayoutsRelated/layout/Layout";
import TextImageComponent from "@/pages/about-us/AboutUsComponents/components/TextImageComponent/TextImageComponent";
import stl from "@/pages/financing/Financing.module.css";
import AnyQust from "@/pages/home/homePageComponents/components/AnyQust/AnyQust";
import Partners from "@/pages/financing/FinancingComponents/Parteners/Partners";
import { useLang } from "@/common/Contexts/LanguageContext";
import BreadCrumb from "@componentsShared/BreadCrumb";
import BenefitsList from "@/pages/financing/FinancingComponents/BenefitsList/BenefitsList";
import WhyFinancing from "@/pages/financing/FinancingComponents/WhyFinancing/WhyFinancing";
import CarMakeContainer from "@/pages/financing/FinancingComponents/CarMakeContainer/CarMakeContainer";
import CarCardsGroup from "@/pages/financing/FinancingComponents/CarCardsGroup/CarCardsGroup";
import Banner from "@componentsShared/Banner/Banner";
import FinanceBanks, { FullBanksList } from "@/pages/financing/financingBanks/FinanceBanks";
import FinancingMakeModel, { BrowseCarsByYears, FinanceDetailsTable, TwoColumnsBulletedList } from "@/pages/financing/financingMakeModel/FinancingMakeModel";
import FinancingMake from "@/pages/financing/financingMake/FinancingMake";
import FinanceLanding, { FinanceLandingHero } from "@/pages/financing/FinancingLanding/FinancingLanding";
import { financeButtonColorHelper, financeImageHelper, financeTranslateText, financeUrlHelper } from "@/pages/financing/helpers/financingHelpers";
import ButtonLink from "@/components/UiKit/Button/ButtonLink";
import { useTranslation } from "@i18n";
import useHelpers from "@hooks/useHelpers";
import { PageNamesEnum } from "@/routes/routes";
var HeaderPanel = function HeaderPanel(_ref) {
  var h1_title = _ref.h1_title,
    subtitle = _ref.subtitle,
    align = _ref.align,
    breadcrumb = _ref.breadcrumb,
    make = _ref.make,
    model = _ref.model;
  align === "center" ? stl.finance2ndPageBanner : stl.finance2ndPageBannerLeft;
  var lang = useLang();
  return __jsx(Banner, {
    className: stl.finance2ndPageBanner
  }, __jsx("div", null, breadcrumb && __jsx("div", {
    className: "BreadcrumbGeneric"
  }, __jsx(BreadcrumbGeneric, {
    breadcrumb: breadcrumb,
    make: make,
    model: model
  })), __jsx("h1", null, financeTranslateText(h1_title, make, model, lang)), __jsx("p", null, financeTranslateText(subtitle, make, model, lang))));
};
var BrowseCarsBy = function BrowseCarsBy(_ref2) {
  var make = _ref2.make,
    model = _ref2.model,
    carsSearchResults = _ref2.carsSearchResults,
    title = _ref2.title,
    cta = _ref2.cta;
  var lang = useLang();
  return __jsx(CarCardsGroup, {
    make: make,
    model: model,
    title: financeTranslateText(title, make, model, lang),
    cta: cta,
    products: carsSearchResults
  });
};
var ButtonLinkContainer = function ButtonLinkContainer(_ref3) {
  var type = _ref3.type,
    url = _ref3.url,
    newTab = _ref3.newTab,
    text = _ref3.text,
    make = _ref3.make,
    model = _ref3.model;
  var lang = useLang();
  var style = {
    backgroundColor: financeButtonColorHelper(type)
  };
  return /*below div has centered content*/__jsx("div", {
    className: "m-hide",
    style: {
      display: "flex",
      justifyContent: "center"
    }
  }, __jsx(ButtonLink, {
    style: style,
    newTab: newTab,
    link: financeUrlHelper(url, lang, make, model)
  }, text));
};
var BenefitsListWrapper = function BenefitsListWrapper(_ref4) {
  var make = _ref4.make,
    model = _ref4.model,
    reverse = _ref4.reverse,
    title = _ref4.title,
    image = _ref4.image,
    points = _ref4.points,
    loading = _ref4.loading;
  var lang = useLang();
  return __jsx(BenefitsList, {
    title: financeTranslateText(title, make, model, lang),
    benefits: points.map(function (point) {
      return financeTranslateText(point.text, make, model, lang);
    }),
    image: financeImageHelper(image.url),
    reversed: reverse,
    loading: loading
  });
};
var BrowseFinancing = function BrowseFinancing(_ref5) {
  var make = _ref5.make,
    model = _ref5.model,
    makes = _ref5.makes,
    models = _ref5.models,
    title = _ref5.title,
    link_base_path = _ref5.link_base_path,
    cta = _ref5.cta;
  var lang = useLang();
  return __jsx(CarMakeContainer, {
    showAsListOnMobile: true,
    title: financeTranslateText(title, make, model, lang),
    carMakesOrModelOrYears: models.length > 0 ? models : makes,
    cta: cta,
    selectedMake: make,
    selectedModel: model,
    link_base_path: link_base_path
  });
};
var ProcessSteps = function ProcessSteps(_ref6) {
  var make = _ref6.make,
    model = _ref6.model,
    title = _ref6.title,
    subtitle = _ref6.subtitle,
    points = _ref6.points;
  var lang = useLang();
  return __jsx(WhyFinancing, {
    title: financeTranslateText(title, make, model, lang),
    description: financeTranslateText(subtitle, make, model, lang),
    points: points.map(function (point) {
      return {
        text: financeTranslateText(point.text, make, model, lang),
        icon: financeImageHelper(point.icon.url)
      };
    })
  });
};
var BanksGroup = function BanksGroup(_ref7) {
  var title = _ref7.title,
    cta = _ref7.cta,
    banks = _ref7.banks;
  var lang = useLang();
  return __jsx(Partners, {
    title: title,
    banks: banks,
    buttonText: cta.text,
    buttonUrl: financeUrlHelper(cta.url, lang)
  });
};
var AboutSyarah = function AboutSyarah(_ref8) {
  var textContent = _ref8.textContent,
    title = _ref8.title,
    image = _ref8.image;
  return __jsx(TextImageComponent, {
    title: title,
    image: financeImageHelper(image.url),
    content: [{
      text: textContent,
      isBullet: false
    }],
    revRow: false,
    className: stl.textImageMargins
  });
};
var NeedHelp = function NeedHelp() {
  return __jsx(AnyQust, null);
};
var BreadcrumbGeneric = function BreadcrumbGeneric(_ref9) {
  var breadcrumb = _ref9.breadcrumb,
    make = _ref9.make,
    model = _ref9.model;
  var lang = useLang();
  var extraLinks = [];
  if (breadcrumb.label1) {
    extraLinks.push({
      link: financeUrlHelper(breadcrumb.link1, lang, make, model),
      title: financeTranslateText(breadcrumb.label1, make, model, lang)
    });
  }
  if (breadcrumb.label2) {
    extraLinks.push({
      link: financeUrlHelper(breadcrumb.link2, lang, make, model),
      title: financeTranslateText(breadcrumb.label2, make, model, lang)
    });
  }
  if (breadcrumb.label3) {
    extraLinks.push({
      link: financeUrlHelper(breadcrumb.link3, lang, make, model),
      title: financeTranslateText(breadcrumb.label3, make, model, lang)
    });
  }
  return __jsx(BreadCrumb, {
    hasSchemaOrg: true,
    className: stl.brdCrmb,
    links: [].concat(extraLinks)
  });
};
export var renderHeadlessComponent = function renderHeadlessComponent(financePageResponse, section) {
  var cheapestCarPriceDetails = financePageResponse.cheapestCarPriceDetails,
    selectedMake = financePageResponse.selectedMake,
    selectedModel = financePageResponse.selectedModel,
    carsSearchResults = financePageResponse.carsSearchResults,
    makes = financePageResponse.makes,
    models = financePageResponse.models;
  switch (section.__component) {
    case ComponentType.HERO:
      return __jsx(FinanceLandingHero, section);
    case ComponentType.HEADER_PANEL:
      return __jsx(HeaderPanel, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.FINANCE_DETAILS:
      return __jsx(FinanceDetailsTable, _extends({}, section, {
        make: selectedMake,
        model: selectedModel,
        cheapestCarPriceDetails: cheapestCarPriceDetails
      }));
    case ComponentType.BUTTON_LINK:
      return __jsx(ButtonLinkContainer, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.BROWSE_CARS:
      return __jsx("div", {
        className: stl.BrowseCarsBy_container
      }, __jsx(BrowseCarsBy, _extends({}, section, {
        carsSearchResults: carsSearchResults,
        make: selectedMake,
        model: selectedModel
      })));
    case ComponentType.BENEFITS_LIST:
      return __jsx(BenefitsListWrapper, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.TWO_COLUMNS_LIST:
      return __jsx(TwoColumnsBulletedList, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.BROWSE_FINANCING:
      return __jsx(BrowseFinancing, _extends({}, section, {
        makes: makes,
        models: models,
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.BROWSE_CARS_BY_YEARS:
      return __jsx(BrowseCarsByYears, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.PROCESS_STEPS:
      return __jsx(ProcessSteps, _extends({}, section, {
        make: selectedMake,
        model: selectedModel
      }));
    case ComponentType.BANKS_GROUP:
      return __jsx(BanksGroup, section);
    case ComponentType.ABOUT_SYARAH:
      return __jsx(AboutSyarah, section);
    case ComponentType.NEED_HELP:
      return __jsx(NeedHelp, section);
    case ComponentType.FULL_BANKS_LIST:
      return __jsx(FullBanksList, section);
    case ComponentType.BREADCRUMB:
      return __jsx("div", {
        className: stl.BreadcrumbGeneric2
      }, __jsx("div", {
        className: "BreadcrumbGeneric"
      }, __jsx(BreadcrumbGeneric, {
        breadcrumb: section,
        make: selectedMake,
        model: selectedModel
      })));
    default:
      return null;
  }
};
var FinancingPageRoot = function FinancingPageRoot() {
  var financingPageResponse = useFullPageData(PageNamesEnum.PAGE_NAME__FinancingPageRoot);
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var pageContent;
  switch (financingPageResponse.slug) {
    case "financing":
      pageContent = __jsx(FinanceLanding, financingPageResponse);
      break;
    case "financing-make":
      pageContent = __jsx(FinancingMake, financingPageResponse);
      break;
    case "financing-make-model":
      pageContent = __jsx(FinancingMakeModel, financingPageResponse);
      break;
    case "financing-full-banks":
      pageContent = __jsx(FinanceBanks, financingPageResponse);
      break;
    default:
      pageContent = __jsx("div", null, "Wrong Financing page");
  }
  return __jsx(Layout, {
    headHideLinks: true,
    hasNavBar: true,
    authenticationMsg: true,
    linksForSearch: true,
    hideOrangeHeadBtn: true,
    disableLoader: true,
    hideAppBanner: true,
    hasMobFooter: true,
    loading: true,
    mainClass: stl.mainFinancingClass,
    customStikeyButton: __jsx("a", {
      className: ["redBtn"].join(" "),
      style: {
        width: "90%",
        padding: "20px",
        margin: "0 auto",
        height: "48px",
        marginTop: "7px",
        borderRadius: "4px"
      },
      href: createUrl("/autos")
    }, t("تصفح جميع السيارات ", {
      ns: "financing_page"
    }))
  }, pageContent);
};
export default FinancingPageRoot;