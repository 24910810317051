import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import React from "react";
var __jsx = React.createElement;
import stl from "./BoxLoading.module.css";
var BoxLoading = function BoxLoading(_ref) {
  var numberOfLoaders = _ref.numberOfLoaders,
    divHeight = _ref.divHeight,
    divWidth = _ref.divWidth,
    className = _ref.className;
  var numberOfLoadersArray = Array.from({
    length: numberOfLoaders
  }, function () {
    return "";
  });
  var innerStyle = _objectSpread(_objectSpread({}, divHeight ? {
    height: divHeight
  } : {}), divWidth ? {
    width: divWidth
  } : {});
  return __jsx(React.Fragment, null, numberOfLoadersArray.map(function (_, i) {
    return __jsx("div", {
      style: innerStyle,
      key: i,
      className: [stl.animatedBackground, className].join(" ")
    });
  }));
};
export default BoxLoading;