import { useState } from "react";
import GrantedPopup from "../../../components/SearchComponent/NewComponents/GrantedPopup/GrantedPopup";
import BottomSheetPopup from "@componentsShared/BottomSheetPopup__OLD/BottomSheetPopup";
import FavoriteNoResult from "./FavoriteNoResult/FavoriteNoResult";
import stl from "./UserFavoriteComponents.module.css";
import { useTranslation } from "@i18n";
import DeskTopBlueMobileWhiteHeader from "../../../components/sharedComponents/DeskTopBlueMobileWhiteHeader/DeskTopBlueMobileWhiteHeader";
import useHelpers from "@hooks/useHelpers";
import UnbxdCard from "@/components/SearchComponent/UnbxdComponents/UnbxdCard/UnbxdCard";


const UserFavoriteComponents = ({
  posts
}) => {
  const { t } = useTranslation(["web_favorite", "web_common"]);
  const {navigateTo} = useHelpers();
  const [showGrantedPopup, setShowGrantedPopup] = useState(false);


  return (
    <div className={stl.wrapper}>
      <DeskTopBlueMobileWhiteHeader
        title={t("سيارات مفضلة", {
          ns: "web_tracking",
        })}
        hasDeskTopBack
        onClick={() => navigateTo("/user/account")}
        customBackground={stl.favPageBG}
        className2={stl.borderBottomFav}
      />

      {!posts?.length && (
        <div className={stl.noDataContainer}>
          <FavoriteNoResult />
        </div>
      )}

      <div className={`${stl.cardsWrapper}  `}>
          {posts?.map((post, i) => {
          return (
              <UnbxdCard
                  className={stl.card}
                  key={i}
                  post={post}
                  showGrantedPopup={() => setShowGrantedPopup(true)}
                  page="favorite"
                  referralLink="favorite_post"
                  CustomTag={"h2"}
                  HasImageSlider={false}
              />
          )
        })}
      </div>
      <BottomSheetPopup
        show={showGrantedPopup}
        onClose={() => setShowGrantedPopup(false)}
      >
        <GrantedPopup />
      </BottomSheetPopup>
    </div>
  );
};

export default UserFavoriteComponents;
