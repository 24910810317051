import React from "react";
var __jsx = React.createElement;
import { Fragment } from "react";
import { renderHeadlessComponent } from "@/pages/financing/FinancingPageRoot";
import { ComponentType } from "../../../../types/models/financing";
var validComponents = [ComponentType.HEADER_PANEL, ComponentType.BENEFITS_LIST, ComponentType.PROCESS_STEPS, ComponentType.BROWSE_FINANCING, ComponentType.BANKS_GROUP, ComponentType.BROWSE_CARS, ComponentType.ABOUT_SYARAH, ComponentType.NEED_HELP];
var FinancingMake = function FinancingMake(financingPageResponse) {
  return financingPageResponse.financePage.contentSections.filter(function (section) {
    return validComponents.includes(section.__component);
  }).map(function (section, index) {
    var isBenefitsListWithId1 = section.id === 3 && section.__component === "sections.benefits-list";
    if (isBenefitsListWithId1) {
      section.loading = "eager"; // Adding the loading attribute directly to the image object
    }
    return __jsx(Fragment, {
      key: index
    }, renderHeadlessComponent(financingPageResponse, section));
  });
};
export default FinancingMake;