/** @format */

import { useState, useRef } from "react";
import stl from "./Input.module.css";
import useIsIOS from "@hooks/useIsIOS";

const Input = ({
  type,
  name,
  id,
  value,
  autoComplete,
  autoFocus,
  disabled,
  maxLength,
  minLength,
  className,
  placeholder,
  extraInfo,
  error,
  onlyNumbers,
  topLabel,
  smallerWidth,
  note,
  inputTitle,
  label,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  onKeyDown = (e) => {},
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const ref = useRef();
  const isIos = useIsIOS();
  
  // Function to determine the input type based on props
  const myType = () => {
    switch (type) {
      case "number":
        return isIos ? "text" : "number";
      case "password":
        return showPassword ? "text" : "password";
      default:
        return "text";
    }
  };

  const orginalType = type;

  const isTargeted =
    isFocused || (value || value === 0 ? true : false) || placeholder;

  const handleChange = (e) => {
    // Convert Arabic digits to English digits
    const arabicToEnglish = (value) => {
      return value.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d) => String(d.charCodeAt(0) - 1632));
    };

    const val = arabicToEnglish(e.target.value);

    // Restrict input to only numbers if onlyNumbers is true
    if (onlyNumbers && !val.match(/^[0-9]*$/)) {
      return; // Prevent invalid input
    }
    
    // Limit the length of the value
    if (maxLength && val.length > maxLength) {
      e.target.value = val.slice(0, maxLength);
    } else {
      e.target.value = val; // Update the input value with converted value
    }

    // Call the onChange prop with the updated value
    onChange(e);
  };

  const handleTextClick = () => {
    if (isTargeted) return;
    ref.current.focus();
  };

  return (
    <div
      className={`${stl.inputWrapper} ${isTargeted ? stl.targeted : ""} ${
        error ? stl.hasError : ""
      } ${className ? className : ""} ${isFocused ? stl.focused : ""} ${
        disabled ? stl.disabled : ""
      }`}
    >
      <input
        ref={ref}
        type={myType()}
        pattern={orginalType === "number" ? "[0-9]*" : undefined}
        name={name}
        id={id}
        value={value}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        disabled={disabled}
        maxLength={maxLength}
        minLength={minLength}
        onKeyDown={(e) => {
          onKeyDown(e);
          if (type === "number" && (e.key === "e" || e.key === "E")) return e.preventDefault();

          if (orginalType === "number") {
            // Allow control keys
            const allowedKeys = [
              "Backspace",
              "Tab",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
            ];

            // Check for valid number input
            const isNumberKey = (key) => {
              const isEnglishNumber = key >= "0" && key <= "9";
              const isArabicNumber = key >= "\u0660" && key <= "\u0669";
              return isEnglishNumber || isArabicNumber;
            };

            // Prevent invalid keypresses
            if (!allowedKeys.includes(e.key) && !isNumberKey(e.key)) {
              e.preventDefault();
            }
          }
        }}
        onChange={handleChange}
        onFocus={() => {
          onFocus();
          setIsFocused(true);
        }}
        onBlur={() => {
          onBlur();
          setIsFocused(false);
        }}
        placeholder={placeholder}
      />
      <span
        onClick={() => {
          !value && handleTextClick();
        }}
        className={stl.label}
      >
        {label}
      </span>
      {topLabel && (
        <span className={stl.topLabel} onClick={handleTextClick}>
          {topLabel}
        </span>
      )}
      {extraInfo && (
        <span onClick={handleTextClick} className={stl.extraInfo}>
          {extraInfo}
        </span>
      )}
      {type === "password" && (
        <img
          src={
            !showPassword
              ? "/assets/images/visibilityEye.svg"
              : "/assets/images/visibilityEyeBlue.svg"
          }
          alt="toggle-password"
          className={`${stl.togglePassword} hasEvents`}
          onClick={() => setShowPassword((pre) => !pre)}
        />
      )}
      {error && <span className={stl.error}>{error}</span>}
      {!error && note && <span className={stl.note}>{note}</span>}
    </div>
  );
};

export default Input;
