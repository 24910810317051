import {
  LOADING,
  FOOTER_LOADING,
  CONTACT_US_SUBMIT,
  CONTACT_US_ERRORS,
} from "../types";
import fetcher from "../../common/axiosInstance";
import axios from "axios";
import { CLIENT_SECRET, apiUrl } from "@globalConfig";

export const loading = (status) => {
    if(__IS_CSR__ && window.SSR_PERIOD_PASSED) {
     return {
            type: LOADING,
            payload: status,
        };
    }
    return {type: null};
};


export const footerLoading = (status) => {
  return {
    type: FOOTER_LOADING,
    payload: status,
  };
};

export const SubmitContactUs = (formData, callback) => async (dispatch) => {
  dispatch(loading(true));
  await dispatch(contactUsSubmit(formData, callback));
  dispatch(loading(false));
};


export const contactUsSubmit = (formData, callback) => async (dispatch) => {
  const res = await fetcher().post(`/site/contact-us`, formData);

  if (
    res.data &&
    res.data.success &&
    res.data.data != null &&
    res?.data?.code != "230"
  ) {
    dispatch({
      type: CONTACT_US_SUBMIT,
      payload: res.data,
    });
    callback && callback();
  } else {
    dispatch({
      type: CONTACT_US_ERRORS,
      payload: res.data,
    });
  }
};




export const healthcheck = (cb) => async (dispatch) => {
  dispatch(loading(true));

  const healthcheckEndPoint = `${apiUrl.replace(
    /\/syarah_v1/,
    ""
  )}/healthcheck`;

  const res = await axios({
    method: "get",
    url: healthcheckEndPoint,
    headers: {
      token: CLIENT_SECRET,
    },
  });

  if (res?.data?.code === 200) if (cb) cb(res.data.data);

  dispatch(loading(false));
};

