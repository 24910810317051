import { useTranslation } from "@i18n";
import { CDN_LINK } from "@globalConfig";
import { useLocation, useNavigate } from "react-router-dom";

import stl from "./DeskTopBlueMobileWhiteHeader.module.css";
import { createUrlWithParams } from "@/utils/common";

const DeskTopBlueMobileWhiteHeader = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["web_tracking"]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showmyAcc = queryParams.get("showmyAcc");

  return (
    <>
      <div
        className={[
          stl.BGDiv,
          props.hideMobile ? "m-hide" : "",
          props.customBackground ? props.customBackground : "",
          props.className ? props.className : "",
        ].join(" ")}
      ></div>
      <div
        className={[
          stl.container,
          props.hideMobile ? "m-hide" : "",
          props.className2 ? props.className2 : "",
        ].join(" ")}
      >
        <div className="container">
          <strong>{props.title}</strong>
        </div>
      </div>
      {(props.hasDeskTopBack || props.hasOnlyMobBack) && (
        <div
          className={[stl.backtnDT, props.hasOnlyMobBack ? "m-show" : ""].join(
            " "
          )}
        >
          <div className="container">
            {+showmyAcc === 1 ? (
              <a href={createUrlWithParams("/user/account")}>
                {" "}
                {t("حسابي", {
                  ns: "web_tracking",
                })}
              </a>
            ) : (
              <span
                onClick={props.onClick ? props.onClick : () => navigate(-1)}
              >
                <img
                  src={CDN_LINK + "/assets/images/arrow_right.svg"}
                  alt="back"
                />
                <span>
                  {t("رجوع", {
                    ns: "web_tracking",
                  })}
                </span>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DeskTopBlueMobileWhiteHeader;
