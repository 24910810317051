import _defineProperty from "/usr/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "@i18n";
import AuthForm from "./AuthForm/AuthForm";
import Loader from "../../components/sharedComponents/Loader";
import BackButton from "../../components/UiKit/Buttons/BackButton/BackButton";
import { customerAuth, togglePostToFavorite } from "@/redux/actions/userActions";
import { getAuthBanner } from "@/redux/actions/authActions";
import useValidate from "@hooks/useValidate";
import stl from "./AuthComponent.module.css";
import { oldUserIdPropertyEvent } from "@/utils/dataLayerEvents";
import { handleHasAccountEvent } from "@/utils/common";
import { trackEvents } from "@/common/events/events";
import { useLang } from "@/common/Contexts/LanguageContext";
import useIsMobile from "@hooks/useIsMobile";
import OtpVerification from "@componentsShared/OTP/OtpVerification";
import useHelpers from "@hooks/useHelpers";
var AuthComponent = function AuthComponent(_ref) {
  var _authData$banner, _authData$banner2;
  var isPopUp = _ref.isPopUp,
    popUpVerifyCb = _ref.popUpVerifyCb,
    isMyAccount = _ref.isMyAccount,
    referral_location = _ref.referral_location;
  var lang = useLang();
  var _useHelpers = useHelpers(),
    navigateTo = _useHelpers.navigateTo;
  var isMobile = useIsMobile();
  var footerLoading = useSelector(function (_ref2) {
    var common = _ref2.common;
    return common.footerLoading;
  });
  var loading = useSelector(function (_ref3) {
    var common = _ref3.common;
    return common.loading;
  });
  var _useSelector = useSelector(function (_ref4) {
      var auth = _ref4.auth;
      return auth;
    }),
    loggedin = _useSelector.loggedin,
    authData = _useSelector.authData,
    authLoading = _useSelector.authLoading;
  var dispatch = useDispatch();
  var navigate = useNavigate();
  var _useTranslation = useTranslation(["web_auth", "web_common"]),
    t = _useTranslation.t;
  var _useValidate = useValidate(),
    validatePhoneNumber = _useValidate.validatePhoneNumber,
    validateName = _useValidate.validateName;
  var _useParams = useParams(),
    carCondition = _useParams.carCondition,
    isOnline = _useParams.isOnline,
    page = _useParams.page,
    postId = _useParams.postId,
    categoryId = _useParams.categoryId,
    event = _useParams.event;
  var _useSearchParams = useSearchParams(),
    _useSearchParams2 = _slicedToArray(_useSearchParams, 1),
    searchParams = _useSearchParams2[0];
  var referralLocParam = searchParams.get("referralLocation");
  var referralLocProps = referral_location;
  var _useState = useState(false),
    showOtp = _useState[0],
    setShowOtp = _useState[1];
  useState(false);
  var _useState2 = useState(""),
    generalError = _useState2[0],
    setGeneralError = _useState2[1];
  var _useState3 = useState({
      full_name: {
        value: "",
        error: ""
      },
      phone_number: {
        value: "",
        error: ""
      },
      otp: {
        value: "",
        error: ""
      }
    }),
    fields = _useState3[0],
    setFields = _useState3[1];
  var _useState4 = useState(false),
    hasAccount = _useState4[0],
    setHasAccount = _useState4[1];
  var handleFieldsChange = function handleFieldsChange(e) {
    var _e$target = e.target,
      name = _e$target.name,
      value = _e$target.value;
    if (name === "phone_number" && value.length > 10) return;
    setFields(function (pre) {
      return _objectSpread(_objectSpread({}, pre), {}, _defineProperty({}, name, {
        error: "",
        value: value
      }));
    });
  };
  var handleSetErrors = function handleSetErrors(errors) {
    errors.forEach(function (error) {
      if (error.name === "general") {
        setGeneralError(error.message);
      } else {
        setFields(function (pre) {
          return _objectSpread(_objectSpread({}, pre), {}, _defineProperty({}, error.name, _objectSpread(_objectSpread({}, pre[error.name]), {}, {
            error: error.message
          })));
        });
      }
    });
  };
  var validate = function validate() {
    var errors = {};
    errors.phone_number = validatePhoneNumber(fields.phone_number.value);
    errors.full_name = validateName(fields.full_name.value);
    return errors;
  };
  var handleSubmit = function handleSubmit(e) {
    e.preventDefault();
    var errors = validate();
    if (errors.phone_number) return handleSetErrors([{
      name: "phone_number",
      message: errors.phone_number
    }], false);
    dispatch(customerAuth(fields.phone_number.value, function (data) {
      setHasAccount(data.hasAccount);
      setShowOtp(true);
    }, handleSetErrors));
  };
  var verifyOtpCb = function verifyOtpCb(userData) {
    var _fields$phone_number;
    handleHasAccountEvent({
      hasAccount: hasAccount,
      status: "Success",
      phone_number: fields === null || fields === void 0 ? void 0 : (_fields$phone_number = fields.phone_number) === null || _fields$phone_number === void 0 ? void 0 : _fields$phone_number.value,
      lang: lang,
      referral_location: referralLocParam ? referralLocParam : referralLocProps
    });
    oldUserIdPropertyEvent(userData.phone_hashed, "login");
    if (page === "search" || page === "postView") {
      dispatch(togglePostToFavorite(postId, isOnline, page, function (isFav) {
        //temporary fix for search page
        if (isFav === true) {
          window.sessionStorage.setItem('favorite_post_id', postId);
          trackEvents("add_favorite", {
            post_id: postId
          });
        }
        navigate(-1);
      }));
      return;
    }
    if (searchParams.get("returnUrl")) {
      navigateTo(searchParams.get("returnUrl"));
      return;
    }
    if (page === "tracking") {
      navigateTo("/tracking-landing");
      return;
    }
    if (popUpVerifyCb) return popUpVerifyCb();
    if (isPopUp) return;
    if (event === "add_favorites" || isMyAccount) return;
    navigate("/user/account/my-info");
  };
  var _onEditNumber = function onEditNumber() {
    setShowOtp(false);
    setFields(function (pre) {
      return _objectSpread(_objectSpread({}, pre), {}, {
        otp: {
          value: "",
          error: ""
        }
      });
    });
  };
  useEffect(function () {
    if (loggedin && !isPopUp) navigate("/");
    dispatch(getAuthBanner(categoryId ? categoryId : 4, carCondition));
    window.addEventListener("popstate", function (e) {
      if (page === "tracking") {
        navigateTo("/");
      }
    });
    return function () {
      setShowOtp(false);
      setGeneralError("");
      setFields({
        full_name: {
          value: "",
          error: ""
        },
        phone_number: {
          value: "",
          error: ""
        },
        otp: {
          value: "",
          error: ""
        }
      });
    };
  }, []);
  useEffect(function () {
    if (isMobile) {
      var header = document.querySelector(".siteHeader");
      !showOtp ? header.classList.add("transparentHeader") : header.classList.remove("transparentHeader");
    }
  }, [showOtp]);
  if (authLoading) return __jsx(Loader, null);
  var renderer = function renderer() {
    if (showOtp) {
      return __jsx(OtpVerification, {
        source: "signup",
        after_verify_callback: verifyOtpCb,
        scenario: hasAccount ? "login" : "signup",
        phone_number: fields.phone_number.value,
        onEditNumber: function onEditNumber() {
          _onEditNumber();
        },
        design: {
          btnText: t("تأكيد الرمز", {
            ns: "web_common"
          }),
          title: t("التحقق من رقم الهاتف", {
            ns: "web_common"
          }),
          className: stl.VerifyOtp,
          event: event
        }
      });
    }
    return __jsx(AuthForm, {
      fields: fields,
      handleFieldsChange: handleFieldsChange,
      onSubmit: handleSubmit,
      generalError: generalError,
      data: authData,
      loading: loading || footerLoading
    });
  };
  return __jsx("div", {
    className: "container ".concat(stl.wrapper, " ").concat(isPopUp ? stl.popUp : "")
  }, __jsx("div", {
    className: stl.imgWrapper
  }, (page === "search" || page === "postView" || page === "tracking") && !showOtp && __jsx(BackButton, {
    onClick: function onClick() {
      if (page === "tracking") return navigateTo("/");
      navigate(-1);
    },
    className: "".concat(stl.backBtn, " ")
  }, t("رجوع", {
    ns: "web_common"
  })), __jsx("img", {
    alt: "banner",
    src: isMobile ? authData === null || authData === void 0 ? void 0 : (_authData$banner = authData.banner) === null || _authData$banner === void 0 ? void 0 : _authData$banner.banner_img_mobile : authData === null || authData === void 0 ? void 0 : (_authData$banner2 = authData.banner) === null || _authData$banner2 === void 0 ? void 0 : _authData$banner2.banner_img_desktop
  })), renderer());
};
export default AuthComponent;