import {useTranslation} from "@i18n";

const useValidate = () => {
  const { t } = useTranslation();

  const validatePhoneNumber = (number) => {
    if (!number.startsWith("05"))
      return t("رقم الجوال يجب أن يبدأ ب 05", {
        ns: "web_common",
      });

    if (number.length !== 10)
      return t("رقم الجوال يجب أن يتكون من عشر خانات", {
        ns: "web_common",
      });

    return "";
  };

  const validateName = (name) => {
    if (name.trim().length < 5)
      return t("الاسم يجب أن لا يقل عن 5 أحرف", {
        ns: "web_common",
      });

    if (name.length < 3 || name.length > 25)
      return t("عدد الحروف يجب أن لا يقل عن 3 حروف وأن لا يزيد عن 25 ", {
        ns: "web_common",
      });

    return "";
  };

  const validateCity = (cityId) => {
    if (!cityId)
      return t("يجب اختيار المدينة", {
        ns: "web_common",
      });

    return "";
  };

  const validateDate = (date) => {
    if (!date)
      return t("يجب اختيار التاريخ", {
        ns: "web_common",
      });

    return "";
  };

  const validateNationalId = (idNumber) => {
    if (idNumber.trim().length !== 10)
      return t("رقم الهوية يجب أن يتكون من 10 خانات", {
        ns: "web_common",
      });

    if (!idNumber.startsWith("1") && !idNumber.startsWith("2"))
      return t("رقم الهوية يجب أن يبدأ ب 1 أو 2", {
        ns: "web_common",
      });
  };

  const validateFamilyMembers = (val) => {
    if (!val.trim())
      return t("هذا الحقل إلزامي", {
        ns: "web_common",
      });
    if (val.trim() >= 100)
      return t("عدد أفراد العائلة يجب أن لا يتجاوز 99 فرد", {
        ns: "web_common",
      });

    return "";
  };

  const validateEmail = (val) => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!val.match(emailRegex))
      return t("يجب ادخال بريد الكتروني صحيح", {
        ns: "web_common",
      });

    return "";
  };

  const validateRequired = (val, message) => {
    if (!val.trim())
      return t(message ? message : "هذا الحقل إلزامي", {
        ns: "web_common",
      });

    return "";
  };

  const validateMinValue = (val, minVal, message) => {
    if (val < minVal)
      return t(message ? message : `القيمة يجب ان لا تقل عن ${minVal}`, {
        ns: "web_common",
      });

    return "";
  };

  const validateMaxValue = (val, maxVal, message) => {
    if (val > maxVal)
      return t(message ? message : `القيمة يجب ان لا تقل عن ${maxVal}`, {
        ns: "web_common",
      });

    return "";
  };

  return {
    validateName,
    validatePhoneNumber,
    validateCity,
    validateDate,
    validateNationalId,
    validateRequired,
    validateFamilyMembers,
    validateEmail,
    validateMinValue,
    validateMaxValue,
  };
};

export default useValidate;
