import React from "react";
var __jsx = React.createElement;
import styles from './RecommendationSlider.module.css';
var RecommendationSliderSkeleton = function RecommendationSliderSkeleton() {
  return __jsx(React.Fragment, null, __jsx("li", {
    className: styles.cardItem
  }, __jsx("div", {
    className: styles.imagePlaceholder
  }), __jsx("div", {
    className: styles.cardBody
  }, __jsx("div", {
    className: styles.cardTitle
  }, __jsx("div", {
    className: styles.tag
  }), __jsx("div", {
    className: styles.titlePlaceholder
  })), __jsx("div", {
    className: styles.cardPrice
  }, __jsx("div", {
    className: styles.priceText
  }))), __jsx("div", {
    className: styles.cardFooter
  }, __jsx("div", {
    className: styles.tagWrapper
  }, __jsx("div", {
    className: styles.tag
  }), __jsx("div", {
    className: styles.tag
  }), __jsx("div", {
    className: styles.tag
  })))));
};
export default RecommendationSliderSkeleton;