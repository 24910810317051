import {useTranslation} from "@i18n";
import { CDN_LINK } from "@globalConfig";
import Button from "../../../../components/UiKit/Button/Button";

import stl from "./FavoriteNoResult.module.css";
import useHelpers from "@hooks/useHelpers";

const FavoriteNoResult = () => {
  const { t } = useTranslation(["web_favorite"]);
  const { navigateTo } = useHelpers();
  return (
    <div className={stl.mainContainer}>
      <img
        width="115px"
        src={CDN_LINK + "/assets/images/fav _empty _state.svg"}
      />
      <h2>
        {t("لا يوجد لديك اي سيارات مفضلة", {
          ns: "web_favorite",
        })}
      </h2>
      <p>
        {t("يمكنك إضافة سيارت للمفضلة للمقارنة وسرعة الوصول اليها", {
          ns: "web_favorite",
        })}
      </p>
      <div className={stl.button}>
        <Button
          type="submit"
          onClick={() => navigateTo("/filters")}
          className={["blueBtn", stl.blueButtonDesign].join(" ")}
        >
          {t("ابدأ البحث", {
            ns: "web_favorite",
          })}
        </Button>
      </div>
    </div>
  );
};

export default FavoriteNoResult;
