import React from "react";
var __jsx = React.createElement;
import stl from "./WhyFinancing.module.css";
var WhyFinancing = function WhyFinancing(_ref) {
  var title = _ref.title,
    description = _ref.description,
    points = _ref.points;
  return __jsx("div", {
    className: stl.whyContainer
  }, __jsx("div", {
    className: "container"
  }, __jsx("div", {
    className: stl.title
  }, __jsx("h2", null, title), __jsx("p", null, description)), __jsx("div", {
    className: stl.points
  }, points.map(function (point) {
    return __jsx("div", {
      className: stl.point
    }, __jsx("img", {
      src: point.icon,
      alt: point.text,
      style: {
        backgroundColor: point.backGround || "#FFFFFF"
      },
      width: "34px",
      height: "44px"
    }), __jsx("p", null, point.text));
  }))));
};
export default WhyFinancing;