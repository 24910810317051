import  {createAxiosInstance} from "@/common/axiosInstance";

const BASE_PATH = 'search_v1';

const searchFetcher = () => {

  const axiosInstance1 = createAxiosInstance(null, BASE_PATH, true);

  return axiosInstance1;
};

export default searchFetcher;
