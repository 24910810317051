import React from "react";
var __jsx = React.createElement;
import { useEffect, useRef } from "react";
import stl from "@componentsShared/SharedPopUp/SharedPopUp.module.css";
var SharedPopUpBody = function SharedPopUpBody(_ref) {
  var children = _ref.children,
    oowh = _ref.oowh,
    _ref$addMinHeight = _ref.addMinHeight,
    addMinHeight = _ref$addMinHeight === void 0 ? false : _ref$addMinHeight,
    owwhPadding = _ref.owwhPadding;
  var ref = useRef(null);
  useEffect(function () {
    if (ref.current && addMinHeight) {
      ref.current.style.minHeight = "".concat(ref.current.scrollHeight, "px");
    }
  }, [ref]);
  return __jsx("div", {
    ref: ref,
    className: [stl.genralModalbody, owwhPadding && stl.owwhPadding].join(" ")
  }, __jsx(React.Fragment, null, children));
};
export default SharedPopUpBody;