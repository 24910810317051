import React from "react";
var __jsx = React.createElement;
import { ComponentType } from "../../../../types/models/financing";
import { Fragment } from "react";
import { renderHeadlessComponent } from "@/pages/financing/FinancingPageRoot";
import { useLang } from "@/common/Contexts/LanguageContext";
import stlFinancingModel from "@/pages/financing/financingMakeModel/FinancingMakeModel.module.css";
import { financeImageHelper, financeTranslateText } from "@/pages/financing/helpers/financingHelpers";
import { useTranslation } from "@i18n";
import CarMakeContainer from "@/pages/financing/FinancingComponents/CarMakeContainer/CarMakeContainer";
export var TwoColumnsBulletedList = function TwoColumnsBulletedList(_ref) {
  var lists = _ref.lists,
    make = _ref.make,
    model = _ref.model;
  var lang = useLang();
  return __jsx("div", {
    className: [stlFinancingModel.twoColumnsList, "container"].join(" ")
  }, lists.map(function (list) {
    return __jsx("div", {
      key: list.title,
      className: stlFinancingModel.listColumn
    }, __jsx("h3", {
      className: stlFinancingModel.listTitle
    }, financeTranslateText(list.title, make, model, lang)), __jsx("ul", {
      className: stlFinancingModel.bulletedList
    }, list.list_items.split('\n').map(function (item, index) {
      return __jsx("li", {
        key: index,
        className: stlFinancingModel.listItem
      }, __jsx("img", {
        src: financeImageHelper(list.icon.url),
        alt: list.icon.caption || "bullet",
        className: stlFinancingModel.bulletIcon,
        width: "24px",
        height: "24px"
      }), __jsx("span", null, financeTranslateText(item, make, model, lang)));
    })));
  }));
};
export var BrowseCarsByYears = function BrowseCarsByYears(_ref2) {
  var make = _ref2.make,
    model = _ref2.model,
    title = _ref2.title,
    link_base_path = _ref2.link_base_path;
  var lang = useLang();
  var years = Array.from({
    length: 10
  }, function (_, i) {
    return new Date().getFullYear() - i + 1;
  });
  return __jsx(React.Fragment, null, __jsx(CarMakeContainer, {
    showAsListOnMobile: true,
    title: financeTranslateText(title, make, model, lang),
    carMakesOrModelOrYears: years.map(function (it) {
      return {
        name_en: it.toString(),
        name: it.toString()
      };
    }),
    selectedMake: make,
    selectedModel: model,
    link_base_path: link_base_path
  }), __jsx("br", null));
};
export var FinanceDetailsTable = function FinanceDetailsTable(_ref3) {
  var make = _ref3.make,
    model = _ref3.model,
    title = _ref3.title,
    disclaimer = _ref3.disclaimer,
    paragraph = _ref3.paragraph,
    cheapestCarPriceDetails = _ref3.cheapestCarPriceDetails;
  if (!(cheapestCarPriceDetails !== null && cheapestCarPriceDetails !== void 0 && cheapestCarPriceDetails.finance_price)) return null;
  var lang = useLang();
  var _useTranslation = useTranslation(),
    t = _useTranslation.t;
  var _cheapestCarPriceDeta = cheapestCarPriceDetails.finance_price,
    first_payment = _cheapestCarPriceDeta.first_payment,
    installment_period = _cheapestCarPriceDeta.installment_period,
    last_payment = _cheapestCarPriceDeta.last_payment,
    monthly_installment = _cheapestCarPriceDeta.text;
  var cash_price = cheapestCarPriceDetails.vat_price.text;
  paragraph = financeTranslateText(paragraph, make, model, lang).replace('{monthly_installment}', monthly_installment).replace('{installment_period}', installment_period).replace('{first_payment}', first_payment).replace('{last_payment}', last_payment).replace('{cash_price}', cash_price);
  return __jsx("div", {
    className: ["container", stlFinancingModel.financingDetailsContainer].join(" ")
  }, __jsx("strong", null, financeTranslateText(title, make, model, lang)), __jsx("div", {
    className: stlFinancingModel.FD_columns
  }, __jsx("div", null, __jsx("div", {
    className: stlFinancingModel.FD_DetailsSection
  }, __jsx("div", null, __jsx("div", {
    className: stlFinancingModel.FD_single_Info
  }, __jsx("strong", null, t("القسط الشهري", {
    ns: "web_common"
  })), __jsx("span", null, t("تبدأ من", {
    ns: "web_common"
  }), ":"), __jsx("strong", null, monthly_installment, __jsx("span", null, t("ريال", {
    ns: "web_common"
  })))), __jsx("div", {
    className: stlFinancingModel.FD_single_Info
  }, __jsx("strong", null, t("مدة القسط", {
    ns: "web_common"
  })), __jsx("span", null, t("عدد الأشهر", {
    ns: "web_common"
  }), ":"), __jsx("strong", null, installment_period, __jsx("span", null, t("شهر", {
    ns: "web_common"
  })))), __jsx("div", {
    className: stlFinancingModel.FD_single_Info
  }, __jsx("strong", null, t("الدفعة الأولى", {
    ns: "web_common"
  })), __jsx("span", null, t("تبدأ من", {
    ns: "web_common"
  }), ":"), __jsx("strong", null, first_payment, __jsx("span", null, t("ريال", {
    ns: "web_common"
  })))), __jsx("div", {
    className: stlFinancingModel.FD_single_Info
  }, __jsx("strong", null, t("الدفعة الأخيرة", {
    ns: "web_common"
  })), __jsx("span", null, t("تبدأ من", {
    ns: "web_common"
  }), ":"), __jsx("strong", null, last_payment, __jsx("span", null, t("ريال", {
    ns: "web_common"
  }))))), __jsx("p", null, paragraph))), __jsx("div", null, __jsx("div", {
    className: stlFinancingModel.FD_single_Info
  }, __jsx("strong", null, t("سعر الكاش يبدأ من", {
    ns: "web_common"
  }), ": "), __jsx("strong", null, cash_price, __jsx("span", null, t("ريال", {
    ns: "web_common"
  }))), __jsx("span", null, "*", t("هذا السعر تقديري", {
    ns: "web_common"
  }))))), __jsx("p", null, financeTranslateText(disclaimer, make, model, lang)));
};
var validComponents = [ComponentType.HEADER_PANEL, ComponentType.FINANCE_DETAILS, ComponentType.BROWSE_CARS, ComponentType.BROWSE_CARS_BY_YEARS, ComponentType.BENEFITS_LIST, ComponentType.BROWSE_FINANCING, ComponentType.PROCESS_STEPS, ComponentType.TWO_COLUMNS_LIST, ComponentType.BANKS_GROUP, ComponentType.ABOUT_SYARAH, ComponentType.NEED_HELP];
var FinancingMakeModel = function FinancingMakeModel(financingPageResponse) {
  return financingPageResponse.financePage.contentSections.filter(function (section) {
    return validComponents.includes(section.__component);
  }).map(function (section, index) {
    return __jsx(Fragment, {
      key: index
    }, renderHeadlessComponent(financingPageResponse, section));
  });
};
export default FinancingMakeModel;