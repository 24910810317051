import stl from "./SubmitButton.module.css";

const SubmitButton = ({
  children,
  onClick,
  className,
  type,
  loading,
  disabled,
}) => {
  return (
    <button
      className={`${stl.btn} ${className ? className : ""} ${
        disabled ? stl.disabled : ""
      }`}
      onClick={onClick}
      type={type ? type : null}
      disabled={disabled}
    >
      {loading ? <span className={stl.loader}></span> : children}
    </button>
  );
};

export default SubmitButton;
