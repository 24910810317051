import React from "react";
var __jsx = React.createElement;
import stl from "./HeroArea.module.css";
var HeroArea = function HeroArea(_ref) {
  var image = _ref.image,
    _ref$title = _ref.title1,
    title1 = _ref$title === void 0 ? "" : _ref$title,
    _ref$title2 = _ref.title2,
    title2 = _ref$title2 === void 0 ? "" : _ref$title2,
    _ref$subTitle = _ref.subTitle,
    subTitle = _ref$subTitle === void 0 ? "" : _ref$subTitle,
    _ref$backGroundColor = _ref.backGroundColor,
    backGroundColor = _ref$backGroundColor === void 0 ? "#266FD1" : _ref$backGroundColor,
    _ref$buttonText = _ref.buttonText,
    buttonText = _ref$buttonText === void 0 ? null : _ref$buttonText,
    _ref$url = _ref.url,
    url = _ref$url === void 0 ? "" : _ref$url,
    _ref$newTab = _ref.newTab,
    newTab = _ref$newTab === void 0 ? false : _ref$newTab;
  var target = newTab ? "_blank" : "_self";
  return __jsx("div", {
    className: [stl.CHA_container].join(" "),
    style: {
      backgroundColor: backGroundColor
    }
  }, __jsx("div", {
    className: "container"
  }, __jsx("div", {
    className: stl.CHA_text
  }, __jsx("h1", {
    className: stl.title
  }, title1), __jsx("p", {
    className: stl.title
  }, title2), __jsx("p", null, subTitle), buttonText && __jsx("a", {
    target: target,
    className: ["redBtn", stl.buttonColor, "m-hide"].join(" "),
    href: url
  }, " ", buttonText)), __jsx("img", {
    src: image,
    alt: title1,
    loading: "eager",
    width: "510px",
    height: "280px"
  })));
};
export default HeroArea;