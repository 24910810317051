// Interfaces
import {ISEO, IImage, ICmsCTA, CTAType, AlignType} from "./cms";



export type FinancingPageSlug = "financing" | "financing-make" | "financing-make-model" | "financing-full-banks";

export interface IFinancingPage {
    shortName: string;
    slug: FinancingPageSlug;
    locale: string;
    contentSections: IContentSection[];
    seo: ISEO;
}



export interface ICTA extends ICmsCTA,IContentSection {

}

export interface IHero extends IContentSection {
    title: string;
    subtitle: string;
    cta: ICTA;
    image: IImage;
}

export interface IHeaderPanel extends IContentSection {
    h1_title: string;
    subtitle: string | null;
    align: AlignType;
    breadcrumb: IFinancePageBreadCrumb;
}

export interface IFinanceDetailsTable extends IContentSection {
    title: string;
    disclaimer: string;
    paragraph: string;
}

export interface IBrowseCarsBy extends IContentSection {
    title: string;
    cta: ICTA;
}

export interface IBenefitsList extends IContentSection {
    title: string;
    reverse: boolean;
    image: IImage;
    points: { text: string; icon: { url: string, caption: string } }[];
    loading: "lazy" | "eager";
}

export interface IBrowseFinancing extends IContentSection {
    title: string;
    link_base_path: string;
    cta: { label: string; type: CTAType };
    loading: "lazy" | "eager";
}

export interface IBrowseCarsByYears extends IContentSection {
    title: string;
    link_base_path: string;
}

export interface IProcessSteps extends IContentSection {
    title: string;
    subtitle: string;
    points: { text: string; icon: { url: string, caption: string } }[];
}

export interface ITwoColumnsList extends IContentSection {
    lists: {
        title: string;
        list_items: string;
        icon: { url: string, caption: string };
    }[];
}

export interface IBanksGroup extends IContentSection {
    title: string;
    cta: ICTA;
    banks: { name: string; logo: { url: string, caption: string } }[];
}

export interface IAboutSyarah extends IContentSection {
    textContent: string;
    title: string;
    image: IImage;
}

export interface INeedHelp extends IContentSection {
    show: boolean;
    wrpperClass: string;
}

export interface IFullBanksList extends IContentSection {
    banks: { name: string; logo: { url: string, caption: string } }[];
}

export interface IFinancePageBreadCrumb extends IContentSection{
    "link1": string;
    "label1": string,
    "link2": string | null,
    "label2": string,
    "link3": null,
    "label3": null
}

export enum ComponentType {
    HERO = "sections.hero",
    HEADER_PANEL = "layout.header-panel-aligned-left",
    FINANCE_DETAILS = "react-custom-components.finance-details-table",
    BROWSE_CARS = "sections.browse-cars-by",
    BENEFITS_LIST = "sections.benefits-list",
    BROWSE_FINANCING = "sections.browse-financing-make-model-year",
    BROWSE_CARS_BY_YEARS = "react-custom-components.browse-cars-by-years",
    PROCESS_STEPS = "sections.process-steps",
    TWO_COLUMNS_LIST = "react-custom-components.two-columns-bulleted-list",
    BANKS_GROUP = "sections.banks-group",
    ABOUT_SYARAH = "sections.about-syarah",
    NEED_HELP = "react-custom-components.do-you-need-help",
    FULL_BANKS_LIST = "react-custom-components.full-banks-list",
    BREADCRUMB = "layout.breadcrumb",
    BUTTON_LINK = "links.button-link",
    SELL_TRADE_HERO = "sell-trade.sell-trade-hero",
    CARDS_SLIDER = "lp-components.cards-slider",
}

export interface IContentSection {
    __component: ComponentType;
}