import {createContext, useContext} from "react";

const IsMobileContext = createContext();

//deprecated
export const useIsMobile = () => {
    if(typeof window !== 'undefined') {
        return window.innerWidth < 1000;
    }
    return useContext(IsMobileContext);
};

export default IsMobileContext;
