import { useState, useCallback } from 'react';
var useBoolean = function useBoolean() {
  var initialValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var _useState = useState(initialValue),
    value = _useState[0],
    setValue = _useState[1];
  var setTrue = useCallback(function () {
    return setValue(true);
  }, []);
  var setFalse = useCallback(function () {
    return setValue(false);
  }, []);
  var toggle = useCallback(function () {
    return setValue(function (v) {
      return !v;
    });
  }, []);
  return {
    value: value,
    setTrue: setTrue,
    setFalse: setFalse,
    toggle: toggle
  };
};
export default useBoolean;