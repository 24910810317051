import { CDN_LINK } from "@globalConfig";
import stl from "./BackButton.module.css";

const BackButton = ({ children, onClick, className = "" }) => {
  return (
    <button
      className={`${stl.backButton} ${className ? className : ""}`}
      onClick={onClick}
      type="button">
      <img src={CDN_LINK + "/assets/images/arrow_right.svg"} alt="" />
      {children}
    </button>
  );
};

export default BackButton;
