import React from "react";

const ArrowSVG = ({ filledColor = "white", className="" }) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : ""}
    >
      <path
        d="M7.71 13.29C8.1 12.9 8.1 12.27 7.71 11.88L3.83 8.00002L19 8.00002C19.55 8.00002 20 7.55002 20 7.00002C20 6.45002 19.55 6.00002 19 6.00002L3.82 6.00002L7.7 2.12002C8.09 1.73002 8.09 1.10002 7.7 0.710019C7.31 0.320019 6.68 0.320019 6.29 0.710019L0.700001 6.30002C0.310001 6.69002 0.310001 7.32002 0.700001 7.71002L6.3 13.29C6.68 13.68 7.32 13.68 7.71 13.29Z"
        fill={filledColor}
      />
    </svg>
  );
};

export default ArrowSVG;
