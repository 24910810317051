import { useEffect } from "react";
import ReactDOM from "react-dom";
import stl from "./BottomSheetPopup.module.css";
import useIsBrowser from "@hooks/useIsBrowser";
import useIsWebView from "@hooks/useIsWebview";

const BottomSheetPopup = ({
  show,
  onClose,
  children,
  mobileDesignChange1 = "",
  className = "",
  blueCloseIcon = "",
  noX = false,
  showXMob = false,
  noPadding = false,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [show]);

  const canUseDOM = useIsBrowser();
  const isWebView =  useIsWebView();
  const renderPopUpHTML = () => {
    // show && (document.body.style.overflow = show ? "hidden" : "");
    return (
      <>
        {show && (
          <div
            className={[stl.genralPopUp, isWebView ? "webview" : ""].join(" ")}
            onClick={(e) => {
              if (e.target === e.currentTarget) {
                onClose();
                document.body.style.overflow = "";
              }
            }}
          >
            <div
              className={`${stl.genralPopUpContent} ${
                className ? className : ""
              }`}
            >
              {!noX && (
                <span
                  className={[
                    stl.closeGenralPopUp,
                    blueCloseIcon ? blueCloseIcon : undefined,
                    stl.Desc,
                    "m-hide",
                  ].join(" ")}
                  onClick={() => {
                    onClose();
                    document.body.style.overflow = "";
                  }}
                />
              )}
              {(!noX || showXMob) && (
                <span
                  className={[
                    stl.closeGenralPopUp,
                    stl.Mobile,
                    "m-show",
                    mobileDesignChange1 ? stl.mobileDesignChange1 : undefined,
                  ].join(" ")}
                  onClick={() => {
                    onClose();
                    document.body.style.overflow = "";
                  }}
                />
              )}
              <div
                className={[
                  stl.genralModalbody,
                  noPadding ? stl.noPadding : undefined,
                ].join(" ")}
              >
                <div>{children}</div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return canUseDOM ? (
    ReactDOM.createPortal(
      renderPopUpHTML(),
      document.getElementById("BodyPortal")
    )
  ) : (
    <></>
  );
};

export default BottomSheetPopup;
