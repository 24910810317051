import React from "react";
var __jsx = React.createElement;
import { useState } from "react";
import cookieClient from "react-cookies/build/cookie";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CDN_LINK, serverUrl } from "@globalConfig";
import AuthPopUp from "@/_MiniApps/AuthComponents/AuthPopUp/AuthPopUp";
import { togglePostToFavorite } from "@/redux/actions/userActions";
import stl from "./AddToFavorite.module.css";
import { trackEvents } from "@/common/events/events";
import ProgressiveImage from "@componentsShared/ProgressiveImage";
import { unbxdAnalytics } from "@/redux/actions/unbxdActions";
import useHelpers from "@hooks/useHelpers";
import SharedPopUp from "@componentsShared/SharedPopUp/SharedPopUp";
import NewArrivalsNotificationModal from "@/_MiniApps/NewArrivalsNotification/NewArrivalsNotificationModal";
import { getUserFromLocalStorage } from "@/utils/auth";
import useNotificationInterests from "./useNotificationInterest";
import useBoolean from "@hooks/useBoolean";
// TODO: later to extract View layer and make the functionality as real HOC
var AddToFavoriteButton = function AddToFavoriteButton(_ref) {
  var isFavorite__raw = _ref.isFavorite,
    postId = _ref.postId,
    authCarCondition = _ref.authCarCondition,
    className = _ref.className,
    page = _ref.page,
    g4_data = _ref.g4_data;
  var _useState = useState(isFavorite__raw),
    isFavorite = _useState[0],
    setIsFavorite = _useState[1];
  var _useSelector = useSelector(function (_ref2) {
      var auth = _ref2.auth;
      return auth;
    }),
    loggedin = _useSelector.loggedin,
    loading = _useSelector.loading;
  var navigate = useNavigate();
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var dispatch = useDispatch();
  var _useState2 = useState(false),
    showAuthModal = _useState2[0],
    setShowAuthModal = _useState2[1];
  var _useNotificationInter = useNotificationInterests(postId, isFavorite),
    tryOpenNotificationModal = _useNotificationInter.tryOpenNotificationModal,
    whatsModalOpen = _useNotificationInter.whatsModalOpen,
    closeWhatsModal = _useNotificationInter.closeWhatsModal;
  var _useBoolean = useBoolean(),
    thanksModalOpen = _useBoolean.value,
    openThanksModal = _useBoolean.setTrue;
  var handleClick = function handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!loggedin && (page === "search" || page === "postView")) return navigate(createUrl("/user/signup/".concat(page, "/5/add_favorites/").concat(postId, "/1/").concat(authCarCondition, "?referralLocation=favorite")));
    if (!loggedin) return setShowAuthModal(true);
    if (loading) return;
    dispatch(togglePostToFavorite(postId, 1, page, function (isFav) {
      if (isFav === true) {
        unbxdAnalytics({
          action: "add_favorites",
          query: "",
          url: serverUrl,
          requestId: "N.A",
          pid: postId
        });
        tryOpenNotificationModal();
        trackEvents("add_favorite", g4_data);
      }
    }));
    setIsFavorite(!isFavorite);
  };
  return __jsx(React.Fragment, null, __jsx("button", {
    id: "add_favorite_button",
    className: [stl.btn, className].join(" "),
    onClick: handleClick,
    "aria-label": "fav"
  }, isFavorite ? __jsx(ProgressiveImage, {
    waitUntilInteractive: true,
    width: 24,
    height: 24,
    src: CDN_LINK + "/assets/images/favorite_red_1.svg",
    alt: "favorite car",
    "aria-label": "favorite car"
  }) : __jsx(ProgressiveImage, {
    waitUntilInteractive: true,
    width: 24,
    height: 24,
    src: CDN_LINK + "/assets/images/favorite_border_blue_1.svg",
    alt: "non-favorite",
    "aria-label": "non-favorite"
  })), __jsx(AuthPopUp, {
    event: "add_favorites",
    show: showAuthModal,
    onClose: function onClose(e) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setShowAuthModal(false);
    },
    categoryId: 5,
    carCondition: authCarCondition,
    popUpVerifyCb: function popUpVerifyCb() {
      dispatch(togglePostToFavorite(postId, 1, page, function (isFav) {
        if (isFav === true) trackEvents("add_favorite", g4_data);
      }));
      setShowAuthModal(false);
    }
  }), __jsx(SharedPopUp, {
    show: whatsModalOpen,
    onClose: function onClose() {
      if (!thanksModalOpen) {
        var _getUserFromLocalStor;
        trackEvents("notification_popup_response", {
          uuid: cookieClient.load("UUID"),
          user_id: ((_getUserFromLocalStor = getUserFromLocalStorage()) === null || _getUserFromLocalStor === void 0 ? void 0 : _getUserFromLocalStor.phone_number) || null,
          popup_response: "No"
        });
      }
      closeWhatsModal();
    },
    oowh: true
  }, __jsx(NewArrivalsNotificationModal, {
    onClose: closeWhatsModal,
    thanksModalOpen: thanksModalOpen,
    openThanksModal: openThanksModal
  })));
};
export default AddToFavoriteButton;