
export type TypeRobotsIndexFollow = 'index,follow' | 'noindex,follow' | 'noindex,nofollow' | null;

export interface ISEO {
    __TEMP__FIXME__isPaginated: boolean;
    metaTitle: string;
    metaDescription: string;
    h1_title?: string;
    canonical?: string;
    shareImage: {
        url: string;
        width: number;
        caption: string;
    };
    preloadImage: {
        url: string;
    };
    preloadImages_USE_THIS_ONLY: string[],
    robots: TypeRobotsIndexFollow;
    openGraph: {
        title?: string;
        type?: string;
        url?: string;
        image?: string;
        description?: string;
    };
    SyarahAppStores:{
        appleItunesApp: string;
        googlePlayApp: string;
    }
    twitter: {
        card: "summary" | "app";
        site: string;
        title?: string;
        canonical_url?: string;
        image: string;
        description: string;
        appInfo: {
            googlePlay: {
                name: string;
                url: string;
                id: string;
            };
            apple: {
                name: string;
                url: string;
                id: string;
            };
        };
    };
    alternateLanguages: {
        ar?: string;
        en?: string;
        default?: string;
        followCanonical?: boolean;
    };
    structuredData: {
        localBusiness?: string;
        website?: string;
        BreadcrumbList?: string;
        CarSchema?: string;
        ItemList?: string;
    };

}


export interface IImage {
    url: string;
    caption: string | null;
    width: number;
    height: number;
}

export interface ICmsCTA {
    url: string;
    newTab: boolean;
    text: string;
    type: CTAType;
    display_mode: DisplayMode;
}

export interface IStickyMobileCTA {
    url: string;
    newTab: boolean;
    text: string;
    type: CTAType;
}

export type DisplayMode = "all" | "desktop" | "mobile";

export enum CTAType {
    PRIMARY = "primary",
    SECONDARY = "secondary"
}

export enum AlignType {
    CENTER = "center",
    SIDE1 = "side1",
    SIDE2 = "side2"
}

export interface IContentSection {
    __component: LandingPageComponentType;
}

export interface ICardsSlider extends IContentSection {
    cards: ICard[];
    bg_color: string;
    bg_color_mobile: string;
    title: string;
    cta?: ICmsCTA;
}

export interface ICard extends IContentSection {
    title: string
    description?: string
    link?: string
    bg_color?: string
    text_color?: string
    image_loading_behavior: IMAGE_LOADING_BEHAVIOR
    image: IImage
}

export type IMAGE_LOADING_BEHAVIOR = "eager" | "lazy"

export enum LandingPageComponentType {
    SELL_TRADE_HERO = "sell-trade.sell-trade-hero",
    CARDS_SLIDER = "lp-components.cards-slider",
}
