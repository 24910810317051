import React from "react";
var __jsx = React.createElement;
import { ComponentType } from "../../../../types/models/financing";
import { Fragment } from "react";
import { renderHeadlessComponent } from "@/pages/financing/FinancingPageRoot";
import { useLang } from "@/common/Contexts/LanguageContext";
import HeroArea from "@componentsShared/HeroArea/HeroArea";
import { financeButtonColorHelper, financeImageHelper, financeUrlHelper } from "@/pages/financing/helpers/financingHelpers";
export var FinanceLandingHero = function FinanceLandingHero(_ref) {
  var title = _ref.title,
    subtitle = _ref.subtitle,
    cta = _ref.cta,
    image = _ref.image;
  var lang = useLang();
  return __jsx(HeroArea, {
    title1: title,
    subTitle: subtitle,
    image: financeImageHelper(image.url),
    imageCaption: image.caption,
    backGroundColor: "#3C56D4",
    buttonText: cta.text,
    buttonColor: financeButtonColorHelper(cta.type),
    url: financeUrlHelper(cta.url, lang),
    newTab: cta.newTab
  });
};
var validComponents = [ComponentType.HERO, ComponentType.BENEFITS_LIST, ComponentType.PROCESS_STEPS, ComponentType.BROWSE_FINANCING, ComponentType.BROWSE_CARS, ComponentType.ABOUT_SYARAH, ComponentType.NEED_HELP, ComponentType.BREADCRUMB, ComponentType.BANKS_GROUP];
var FinanceLanding = function FinanceLanding(financingPageResponse) {
  return financingPageResponse.financePage.contentSections.filter(function (section) {
    return validComponents.includes(section.__component);
  }).map(function (section, index) {
    return __jsx(Fragment, {
      key: index
    }, renderHeadlessComponent(financingPageResponse, section));
  });
};
export default FinanceLanding;