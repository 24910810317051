import stl from "./MarkitingLabel.module.css";

const MarkitingLabel = ({ campaign }) => {
  return (
    <div
      className={stl.markitingLabel}
      style={{ backgroundColor: campaign.bg_color }}
    >
      <span>{campaign.txt}</span>
    </div>
  );
};

export default MarkitingLabel;
