import { CDN_LINK } from "@globalConfig";
import SubmitButton from "../../../components/UiKit/Buttons/SubmitButton/SubmitButton";
import { useTranslation } from "@i18n";
import stl from "./OtpLimitExceeded.module.css";

const OtpLimitExceeded = ({ onClick, className }) => {
  const { t } = useTranslation(["web_common"]);
  return (
    <div className={`${stl.wrapper} ${className ? className : ""}`}>
      <img src={CDN_LINK + "/assets/images/time-bomb.svg"} alt="" />
      <strong>{t("يرجى المحاولة بعد 24 ساعة", {
        ns: "web_common",
      })}</strong>
      <p>
        {t("لقد تجاوزت الحد المسموح به من محاولات اضافة رمز التحقق . يرجى إعادة المحاولة بعد 24 ساعة.", {
          ns: "web_common",
        })}
      </p>
      <SubmitButton type="button" onClick={onClick}>
        {t("العودة للرئيسية", {
          ns: "web_common",
        })}
      </SubmitButton>
    </div>
  );
};

export default OtpLimitExceeded;
