import { useState, useRef, useEffect } from "react";
import { CDN_LINK } from "@globalConfig";
import stl from "./HeroArea2.module.css";
import useIsFirstInteraction from "@hooks/useIsFirstInteraction";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

const HeroArea2 = (props) => {
  const isFirstInteraction = useIsFirstInteraction();
  const [slides, setSlides] = useState((props.children || []).slice(0, 1));
  const [fullSlidesCount, setFullSlidesCount] = useState(
    (props.children || []).length
  );

  const [activeIndex, setActiveIndex] = useState(0);
  const [click, setClick] = useState(true);

  const sliderRef = useRef(null);
  const interval = useRef(null);

  useEffect(() => {
    if (!isFirstInteraction) return;
    setSlides(props.children || []);
  }, [isFirstInteraction]);

  useEffect(() => {
    if (!isFirstInteraction) return;
    setTimeout(() => {
      setClick(true);
    }, 1000);
  }, [click, isFirstInteraction]);

  useEffect(() => {
    if (!isFirstInteraction) return;
    autoPlay();
  }, [activeIndex, isFirstInteraction]);

  const autoPlay = () => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      updateIndex(activeIndex + 1);
    }, 5000);
    interval.current;
  };

  const updateIndex = (val) => {
    setClick(false);
    if (val === props.children?.length) {
      setActiveIndex(0);
    } else if (val < 0) {
      setActiveIndex(props.children?.length - 1);
    } else setActiveIndex(val);
    autoPlay();
  };

  // touchEvents
  const [xDown, setXDown] = useState(null);
  const [yDown, setYDown] = useState(null);
  const [movment, setMovment] = useState(0);
  const [numberOfFingers, setNumberOfFingers] = useState(0);

  const getTouches = (evt) => {
    return evt.touches || evt.originalEvent.touches;
  };

  function handleTouchStart(evt) {
    clearInterval(interval.current);
    setNumberOfFingers(evt.touches.length);
    sliderRef.current.style.transition = "0s";
    const firstTouch = getTouches(evt)[0];
    setXDown(firstTouch.clientX);
    setYDown(firstTouch.clientY);
  }

  const handleTouchSwipe = (evt) => {
    if (numberOfFingers === 1) {
      if (!xDown || !yDown) {
        return;
      }

      setMovment(evt.touches[0].clientX - xDown);

      let currentleft = 0;

      let newLeftMove = (evt.touches[0].clientX - xDown) / 15;

      // uncoment for movment
      sliderRef.current
        .querySelectorAll(".singleslide")
        .forEach(function (item) {
          item.style.left = currentleft + newLeftMove + "px";
          item.style.transition = "0s";
        });
      // uncoment for movment
    }
  };

  const handleTouchEnd = (evt) => {
    /*most significant*/
    const { dir } = document.querySelector("body");
    if (numberOfFingers === 1) {
      sliderRef.current.style.transition = "";

      if (movment > 70 || movment < -70) {
        if (movment < 0) {
          dir === "rtl"
            ? updateIndex(activeIndex - 1)
            : updateIndex(activeIndex + 1);
        } else {
          dir === "rtl"
            ? updateIndex(activeIndex + 1)
            : updateIndex(activeIndex - 1);
        }
      }
    }
    /* reset values */
    setXDown(null);
    setYDown(null);
    setNumberOfFingers(0);
    setMovment(0);

    // uncoment for movment
    sliderRef.current.querySelectorAll(".singleslide").forEach(function (item) {
      item.style.left = "";
      item.style.transition = "";
    });
    // uncoment for movment
    autoPlay();
  };

  return (
    <>
      <div
        onMouseDown={() => clearInterval(interval.current)}
        onMouseUp={autoPlay}
        className={stl.sliderConainer}
        ref={sliderRef}
        onTouchStart={(e) => {
          e.touches.length === 1 && handleTouchStart(e);
        }}
        onTouchMove={(e) => {
          e.touches.length === 1 && handleTouchSwipe(e);
        }}
        onTouchEnd={handleTouchEnd}
      >
        {slides.map((slide, idx) => {
          return (
            <div
              key={"slideS" + idx}
              className={[
                stl.singleslide,
                "singleslide",
                idx === activeIndex ? stl.activeSlide : undefined,
                idx === activeIndex ? "activeSlide" : undefined,
                idx > activeIndex ? stl.nextSlide : undefined,
                idx < activeIndex ? stl.prevSlide : undefined,
                activeIndex === props.children?.length - 1 && idx === 0
                  ? stl.actAsLst
                  : undefined,
                idx === props.children?.length - 1 && activeIndex === 0
                  ? stl.actAsfirst
                  : undefined,
              ].join(" ")}
            >
              {slide}
            </div>
          );
        })}

        <span
          onClick={() => {
            click && updateIndex(activeIndex - 1);
          }}
          className={["m-hide", stl.RightArr].join(" ")}
        >
          <ProgressiveImage
            waitUntilInteractive
            width={32}
            height={32}
            fetchpriority="low"
            src={CDN_LINK + "/assets/images/right-arrow2.svg"}
            alt="a"
          />
        </span>
        <span
          onClick={() => {
            click && updateIndex(activeIndex + 1);
          }}
          className={["m-hide", stl.leftArr].join(" ")}
        >
          <ProgressiveImage
            waitUntilInteractive
            width={32}
            height={32}
            fetchpriority="low"
            src={CDN_LINK + "/assets/images/leftArrow2.svg"}
            alt="a"
          />
        </span>
      </div>

      <ul className={stl.dots}>
        {Array.from({ length: fullSlidesCount }).map((_, idx) => {
          return (
            <li
              key={"dots" + idx}
              onClick={() => setActiveIndex(idx)}
              className={activeIndex === idx ? stl.active : undefined}
            />
          );
        })}
      </ul>
    </>
  );
};

export default HeroArea2;
