import _objectSpread from "/usr/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import React from "react";
var __jsx = React.createElement;
import { api__sendOtp } from "@/apis/otp/sendOtp";
import { api__verifyOtp } from "@/apis/otp/verifyOtp";
import { useEffect, useState } from "react";
import OtpLimitExceeded from "@componentsShared/OTP/OtpLimitExceeded/OtpLimitExceeded";
import { useNavigate } from "react-router-dom";
import VerifyOtp from "@componentsShared/OTP/VerifyOtp";
import { useTranslation } from "@i18n";
import { ValidationException } from "@/common/exceptions/ValidationException";
import Loader from "@/components/sharedComponents/Loader";
import { getUserFromLocalStorage, setAccessToken, setUserId } from "@/utils/auth";
import { setUserData } from "@/redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
var DEFAULT_DESIGN = function DEFAULT_DESIGN(t) {
  return {
    label: t("أدخل رمز التحقق للاستمرار", {
      ns: "web_auth"
    }),
    btnText: t("تأكيد رقم الجوال", {
      ns: "web_auth"
    }),
    title: "",
    editNumberClass: "",
    subClassName: "",
    otpBtn: "",
    className: ""
  };
};
var OtpVerification = function OtpVerification(_ref) {
  var _ref$forceSend = _ref.forceSend,
    forceSend = _ref$forceSend === void 0 ? false : _ref$forceSend,
    _ref$design = _ref.design,
    design = _ref$design === void 0 ? {} : _ref$design,
    _ref$otp_length = _ref.otp_length,
    otp_length = _ref$otp_length === void 0 ? 6 : _ref$otp_length,
    source = _ref.source,
    after_verify_callback = _ref.after_verify_callback,
    _ref$after_submit_otp = _ref.after_submit_otp_callback,
    after_submit_otp_callback = _ref$after_submit_otp === void 0 ? function () {} : _ref$after_submit_otp,
    _ref$fail_callback = _ref.fail_callback,
    fail_callback = _ref$fail_callback === void 0 ? undefined : _ref$fail_callback,
    scenario = _ref.scenario,
    _ref$phone_number = _ref.phone_number,
    phone_number = _ref$phone_number === void 0 ? "" : _ref$phone_number,
    onEditNumber = _ref.onEditNumber,
    _ref$FIX_ME__extra_pa = _ref.FIX_ME__extra_params,
    FIX_ME__extra_params = _ref$FIX_ME__extra_pa === void 0 ? {} : _ref$FIX_ME__extra_pa;
  var _useTranslation = useTranslation(["web_common"]),
    t = _useTranslation.t;
  design = _objectSpread(_objectSpread({}, DEFAULT_DESIGN(t)), design);
  var _useSelector = useSelector(function (state) {
      return state.auth;
    }),
    loggedin = _useSelector.loggedin;
  var navigate = useNavigate();
  var dispatch = useDispatch();
  var userData = getUserFromLocalStorage();
  var _useState = useState(false),
    isOtpLimitExceeded = _useState[0],
    setIsOtpLimitExceeded = _useState[1];
  var _useState2 = useState(false),
    isOtpSent = _useState2[0],
    setIsOtpSent = _useState2[1];
  var _useState3 = useState(false),
    loading = _useState3[0],
    setLoading = _useState3[1];
  var _useState4 = useState(false),
    pageReady = _useState4[0],
    setPageReady = _useState4[1];
  var _useState5 = useState(""),
    otp = _useState5[0],
    setOtp = _useState5[1];
  var _useState6 = useState(null),
    validationException = _useState6[0],
    setValidationException = _useState6[1];
  var afterVerify = function afterVerify(userData) {
    setAccessToken(userData.token);
    setUserId(userData.id);
    dispatch(setUserData(userData));
    after_verify_callback(userData);
  };
  useEffect(function () {
    if (!loggedin || forceSend) {
      sendOtpToPhoneNumber(phone_number, source);
    } else {
      afterVerify(userData);
    }
  }, []);
  useEffect(function () {
    // apply auto submit
    if (otp.length === otp_length) {
      onSubmitOtp();
    }
  }, [otp]);
  var handle__resend__sendOtp = function handle__resend__sendOtp() {
    setIsOtpSent(true);
    setOtp("");
    sendOtpToPhoneNumber(phone_number, source);
  };
  var sendOtpToPhoneNumber = function sendOtpToPhoneNumber(phone_number, source) {
    setLoading(true);
    setValidationException(null);
    api__sendOtp({
      phone_number: phone_number,
      otp_length: otp_length,
      source: source
    }).then(function () {
      setPageReady(true);
    })["catch"](function (error) {
      if (error instanceof ValidationException) {
        //if (error.name === 'ValidationException') {
        var exp = error;
        if (exp.isError('blocked')) {
          setIsOtpLimitExceeded(true);
          setPageReady(true);
          return;
        }
        throw error;
      }
    })["finally"](function () {
      setLoading(false);
    });
  };
  var onSubmitOtp = function onSubmitOtp() {
    setLoading(true);
    setValidationException(null);
    api__verifyOtp(_objectSpread({
      phone_number: phone_number,
      otp: Number(otp),
      source: source,
      scenario_text: scenario
    }, FIX_ME__extra_params)).then(function (userData) {
      after_submit_otp_callback();
      afterVerify(userData);
    })["catch"](function (err) {
      if (err instanceof ValidationException) {
        setValidationException(err);
        return;
      }
      throw err;
    })["finally"](function () {
      setIsOtpSent(false);
      setLoading(false);
    });
  };
  if (!pageReady) return __jsx(Loader, {
    smallLoader: true
  });
  if (loggedin && forceSend === false) return __jsx(Loader, {
    smallLoader: true
  });
  if (isOtpLimitExceeded) return __jsx(OtpLimitExceeded, {
    onClick: function onClick() {
      return navigate("/?ref=otp_limit_exceeded&source=" + source);
    },
    className: design.className ? design.className : ""
  });
  return __jsx(VerifyOtp, {
    value: otp,
    phoneNumber: phone_number,
    loading: loading,
    subClassName: design.subClassName,
    title: design.title,
    label: design.label,
    className: design.className,
    btnText: design.btnText,
    otpBtn: design.otpBtn,
    length: otp_length,
    error: validationException === null || validationException === void 0 ? void 0 : validationException.extractErrorMessageFrom(['otp', 'blocked']),
    isOtpSent: isOtpSent,
    onChange: setOtp,
    onValidate: onSubmitOtp,
    onEdit: onEditNumber,
    onResend: handle__resend__sendOtp,
    setIsOtpSent: setIsOtpSent,
    editNumberClass: design.editNumberClass
  });
};
export default OtpVerification;