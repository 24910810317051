import _slicedToArray from "/usr/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useSelector } from "react-redux";
import AuthComponent from "@/_MiniApps/AuthComponents/AuthComponent";
import Layout from "@/_LayoutsRelated/layout/Layout";
import UserFavoriteComponents from "@/pages/user/UserFavoriteComponents/UserFavoriteComponents";
import { api__listFavourite } from "@/apis/customer/list-favourite";
import useApi from "@hooks/useApi";
import Loader from "@/components/sharedComponents/Loader";
var UserFavorite = function UserFavorite() {
  var _useSelector = useSelector(function (_ref) {
      var auth = _ref.auth;
      return auth;
    }),
    loggedin = _useSelector.loggedin;
  if (!loggedin) return __jsx(AuthComponent, {
    isPopUp: false
  });
  var _useApi = useApi([], api__listFavourite),
    _useApi2 = _slicedToArray(_useApi, 3),
    loading = _useApi2[0],
    error = _useApi2[1],
    posts = _useApi2[2];
  if (loading) return __jsx(Loader, null);
  return __jsx(Layout, {
    linksForSearch: true,
    hideHeaderOnmobile: true,
    hideFooter: true,
    disableLoader: true,
    hideAppBanner: true,
    hasNavBar: true,
    pageName: "favorite"
  }, __jsx(UserFavoriteComponents, {
    posts: posts
  }));
};
export default UserFavorite;