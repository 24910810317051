import { useEffect, useState } from 'react';
function useIsBrowser() {
  var _useState = useState(false),
    isBrowser = _useState[0],
    setIsBrowser = _useState[1];
  useEffect(function () {
    setIsBrowser(true);
  }, []);
  return isBrowser;
}
export default useIsBrowser;