import React from "react";
var __jsx = React.createElement;
import { useState } from "react";
import stl from "./CarMakeContainer.module.css";
import SubmitButton from "@/components/UiKit/Buttons/SubmitButton/SubmitButton";
import useHelpers from "@hooks/useHelpers";
var CarMakeContainer = function CarMakeContainer(props) {
  var _props$carMakesOrMode, _props$carMakesOrMode2, _props$carMakesOrMode3, _props$carMakesOrMode4, _props$carMakesOrMode5;
  var title = props.title,
    cta = props.cta;
  var _useState = useState(false),
    showAllMakes = _useState[0],
    setShowAllMakes = _useState[1];
  var _useHelpers = useHelpers(),
    createUrl = _useHelpers.createUrl;
  var toggleAllMakes = function toggleAllMakes() {
    setShowAllMakes(!showAllMakes);
  };
  var hasShowMoreButton = cta && props.carMakesOrModelOrYears.length > 8;
  var createLink = function createLink(item) {
    var _props$selectedMake, _props$selectedMake$n, _props$selectedModel, _props$selectedModel$, _item$name_en;
    var makeSlug = (_props$selectedMake = props.selectedMake) === null || _props$selectedMake === void 0 ? void 0 : (_props$selectedMake$n = _props$selectedMake.name_en) === null || _props$selectedMake$n === void 0 ? void 0 : _props$selectedMake$n.slugify();
    var modelSlug = (_props$selectedModel = props.selectedModel) === null || _props$selectedModel === void 0 ? void 0 : (_props$selectedModel$ = _props$selectedModel.name_en) === null || _props$selectedModel$ === void 0 ? void 0 : _props$selectedModel$.slugify();
    var itemSlug = item === null || item === void 0 ? void 0 : (_item$name_en = item.name_en) === null || _item$name_en === void 0 ? void 0 : _item$name_en.slugify();
    var link = props.link_base_path;
    if (makeSlug) {
      link = link.replace("{make}", "/".concat(makeSlug));
    } else {
      link = link.replace("{make}", "");
    }
    if (modelSlug) {
      link = link.replace("{model}", "/".concat(modelSlug));
    } else {
      link = link.replace("{model}", "");
    }
    link = link.replace("{year}", "");
    return createUrl("".concat(link, "/").concat(itemSlug));
  };
  return __jsx(React.Fragment, null, __jsx("section", {
    className: "".concat(stl.brands_container, " ").concat(props !== null && props !== void 0 && props.showAsListOnMobile ? stl.CMSCarsContainer : undefined)
  }, __jsx("h2", {
    className: "container big"
  }, title), __jsx("div", {
    className: "".concat(stl.brands_makesContainer, " ").concat(stl.makesWithLogo, " ").concat(props !== null && props !== void 0 && props.showAsListOnMobile ? stl.showAsListOnMobile : undefined)
  }, props === null || props === void 0 ? void 0 : (_props$carMakesOrMode = props.carMakesOrModelOrYears) === null || _props$carMakesOrMode === void 0 ? void 0 : (_props$carMakesOrMode2 = _props$carMakesOrMode.slice(0, 8)) === null || _props$carMakesOrMode2 === void 0 ? void 0 : _props$carMakesOrMode2.map(function (make, idx) {
    return __jsx("a", {
      key: idx,
      href: createLink(make),
      className: " ".concat(stl.singleLogoMake),
      "aria-label": make.name
    }, make.icon_url && __jsx("div", {
      className: stl.imgContainer
    }, __jsx("img", {
      loading: "lazy",
      width: 90,
      height: 90,
      src: make.icon_url,
      alt: make.name
    })), __jsx("h3", {
      className: stl.makeName
    }, make.name));
  }), props === null || props === void 0 ? void 0 : (_props$carMakesOrMode3 = props.carMakesOrModelOrYears) === null || _props$carMakesOrMode3 === void 0 ? void 0 : (_props$carMakesOrMode4 = _props$carMakesOrMode3.slice(8)) === null || _props$carMakesOrMode4 === void 0 ? void 0 : _props$carMakesOrMode4.map(function (make, idx) {
    return __jsx("a", {
      key: idx,
      href: createLink(make),
      className: " ".concat(stl.singleLogoMake, " m-show"),
      "aria-label": make.name
    }, __jsx("div", {
      className: stl.imgContainer
    }, __jsx("img", {
      width: 90,
      height: 90,
      src: make.icon_url,
      alt: make.name
    })), __jsx("h3", {
      className: stl.makeName
    }, make.name));
  })), hasShowMoreButton && !showAllMakes && __jsx(SubmitButton, {
    onClick: function onClick() {
      toggleAllMakes();
    },
    className: ["blueBtn", !(props !== null && props !== void 0 && props.showAsListOnMobile) ? "m-hide" : undefined, stl.viewAllMakes, stl.button].join(" "),
    type: "button",
    loading: false,
    disabled: false
  }, cta === null || cta === void 0 ? void 0 : cta.label), hasShowMoreButton && showAllMakes && __jsx("div", {
    className: "".concat(stl.allCarsMakeText)
  }, __jsx("h3", null, cta === null || cta === void 0 ? void 0 : cta.label), __jsx("ul", null, props === null || props === void 0 ? void 0 : (_props$carMakesOrMode5 = props.carMakesOrModelOrYears.slice(8)) === null || _props$carMakesOrMode5 === void 0 ? void 0 : _props$carMakesOrMode5.map(function (item, idx) {
    return __jsx("li", {
      key: idx
    }, __jsx("a", {
      href: createLink(item)
    }, "".concat(item.name)));
  })))));
};
export default CarMakeContainer;