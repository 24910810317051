import React from "react";
var __jsx = React.createElement;
import SlideContent from "./SlideContent/SlideContent";
import stl from "../../HomePage.module.css";
import useIsMobile from "@hooks/useIsMobile";
import useIsFirstInteraction from "@hooks/useIsFirstInteraction";
import { useEffect, useState } from "react";
import { thumbUrl } from "../../../../../utils/imagesHelpers";
var UspSlider = function UspSlider(_ref) {
  var usp_slider = _ref.usp_slider,
    _ref$isEager = _ref.isEager,
    isEager = _ref$isEager === void 0 ? false : _ref$isEager;
  var isFirstInteraction = useIsFirstInteraction();
  var isMobile = useIsMobile();
  if (!(usp_slider !== null && usp_slider !== void 0 && usp_slider.images) || !(usp_slider !== null && usp_slider !== void 0 && usp_slider.title)) return null;
  var has3 = usp_slider.images.length <= 3;
  var _useState = useState(usp_slider.images.slice(0, isMobile ? 2 : 4)),
    images = _useState[0],
    setImages = _useState[1];
  useEffect(function () {
    if (!isFirstInteraction) return;
    setImages(usp_slider.images);
  }, [isFirstInteraction]);
  var items = images.map(function (img) {
    return {
      bgColor: "linear-gradient(0deg, rgba(241,246,252,1) 0%, rgba(249,251,254,1) 100%)",
      imageUrl: thumbUrl(img.image, "homepage-usp-slider-images-v1", isMobile ? "0x300" : "0x426"),
      progressiveImageUrl: !isEager ? null : thumbUrl(img.image, "homepage-usp-slider-images-v1", isMobile ? "0x154" : "0x300")
    };
  });
  return __jsx(SlideContent, {
    isEager: isEager,
    has3: has3,
    titleClass: stl.CustomContainer,
    containerClass: stl.CustomContainer,
    title: usp_slider.title,
    items: items
  });
};
export default UspSlider;