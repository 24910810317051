import React from "react";
var __jsx = React.createElement;
import { useEffect, useRef, useState } from "react";
import useIsFirstInteraction from "./useIsFirstInteraction";
var LazyLoadComponent = function LazyLoadComponent(_ref) {
  var loadComponent = _ref.loadComponent,
    _ref$waitInterActive = _ref.waitInterActive,
    waitInterActive = _ref$waitInterActive === void 0 ? true : _ref$waitInterActive,
    _ref$renderOnSSR = _ref.renderOnSSR,
    renderOnSSR = _ref$renderOnSSR === void 0 ? true : _ref$renderOnSSR;
  // Added "const" keyword before the function name
  if (__IS_SSR__ && renderOnSSR) {
    return loadComponent();
  }
  var isFirstInteraction = useIsFirstInteraction();
  var _useState = useState(false),
    shouldLoad = _useState[0],
    setShouldLoad = _useState[1];
  var targetRef = useRef(null);
  useEffect(function () {
    if (waitInterActive && !isFirstInteraction) {
      return;
    }
    var observer = new IntersectionObserver(function (entries) {
      var entry = entries[0];
      if (entry.isIntersecting) {
        setShouldLoad(true);
        observer.disconnect();
      }
    }, {
      root: null,
      rootMargin: "0px 0px 50px 0px",
      threshold: 0.99
    });
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return function () {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [shouldLoad, isFirstInteraction]);
  return __jsx("div", {
    ref: targetRef
  }, shouldLoad ? loadComponent() : __jsx(React.Fragment, null));
};
export default LazyLoadComponent;