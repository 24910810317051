import React from "react";
var __jsx = React.createElement;
import stl from "./Banner.module.css";
var Banner = function Banner(_ref) {
  var children = _ref.children,
    className = _ref.className;
  return __jsx(React.Fragment, null, __jsx("div", {
    className: [stl.BC_container, className !== null && className !== void 0 ? className : undefined].join(" ")
  }, children));
};
export default Banner;